import React from 'react';
import { motion } from 'framer-motion';

export function ImagePopup({ imageUrl, closePopup }) {
  return (
    <motion.div 
      className="full-size-image-popup" 
      onClick={closePopup}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className="image-container"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        onClick={(e) => e.stopPropagation()}
      >
        <img src={imageUrl} alt="Full size" />
        <motion.button 
          className="close-button"
          onClick={closePopup}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          ×
        </motion.button>
      </motion.div>
    </motion.div>
  );
}