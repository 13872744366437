import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { isNSFW } from '../utils/helpers';
import Ad from './Ad';
import loadingImage from '../assets/loading-animation.png';
import noresultImage from '../assets/noresult.png';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

export function renderResult(hit, isInStarredView = false, settings, toggleStar, starredResults, mainImages, t, openFullSizeImage, starredFolders, moveToFolder) {
  const source = hit._source || {};
  const url = new URL(source.url || '');
  const isStarred = starredResults.some(r => r._id === hit._id);
  const mainImage = mainImages[hit._id];
  const contentIsNSFW = 
    isNSFW(source.title) || 
    isNSFW(source.meta_description) || 
    isNSFW(source.url);

  if (settings.nsfwFilter && contentIsNSFW) {
    return null;
  }

  return (
    <motion.div 
      key={hit._id} 
      className={`search-result ${settings.showNsfwEffect && contentIsNSFW ? 'nsfw-result' : ''}`}
      variants={itemVariants}
    >
      {settings.showNsfwEffect && contentIsNSFW && <span className="nsfw-indicator">{t('nsfw')}</span>}
      <div className="result-content">
        <div className="result-header">
          <img
            src={`https://www.google.com/s2/favicons?domain=${url.hostname}`}
            alt="Site favicon"
            className="favicon"
          />
          <div className="attribution">
            {source.url_host || t('unknown')} • {source.last_crawled_at ? new Date(source.last_crawled_at).toLocaleDateString() : t('unknownDate')}
          </div>
          <motion.button 
            className={`star-button ${isStarred ? 'starred' : ''}`}
            onClick={() => toggleStar(hit)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {isStarred ? '★' : '☆'}
          </motion.button>
        </div>
        <h3 className="title-link">
          <a href={source.url || '#'}>{source.title || t('noTitle')}</a>
        </h3>
        <div className="visible-url">{url.origin + url.pathname}</div>
        <div className="snippet">{source.meta_description || t('noDescription')}</div>
        {source.links && source.links.length > 1 && (
          <div className="sitelinks-group">
            {source.links.slice(0, 4).map((link, index) => (
              <a key={index} href={link} className="sitelink">
                {new URL(link).pathname.replace(/^\//, '') || t('link')}
              </a>
            ))}
          </div>
        )}
        {isInStarredView && starredFolders && moveToFolder && (
          <select 
            value={Object.keys(starredFolders).find(folder => starredFolders[folder].includes(hit._id)) || ''}
            onChange={(e) => moveToFolder(hit._id, e.target.value)}
          >
            <option value="">{t('noFolder')}</option>
            {Object.keys(starredFolders).map(folder => (
              <option key={folder} value={folder}>{folder}</option>
            ))}
          </select>
        )}
      </div>
      {settings.showImages && mainImage && (
        <div className="result-image-container">
          <motion.img 
            src={mainImage.thumbnail} 
            alt="Main content" 
            className="main-image-thumbnail"
            onClick={() => openFullSizeImage(mainImage.fullSize)}
            whileHover={{ scale: 1.05 }}
          />
        </div>
      )}
    </motion.div>
  );
}

export function SearchResults({ 
  results, 
  isLoading, 
  error, 
  currentPage, 
  totalHits, 
  goToNextPage, 
  goToPreviousPage, 
  toggleStar, 
  starredResults, 
  mainImages,
  settings,
  t,
  openFullSizeImage,
  starredFolders,
  moveToFolder,
  ads
}) {
  const filteredResults = results.filter(hit => {
    const source = hit._source || {};
    const contentIsNSFW = 
      isNSFW(source.title) || 
      isNSFW(source.meta_description) || 
      isNSFW(source.url);
    return !(settings.nsfwFilter && contentIsNSFW);
  });

  const resultsWithAds = [];
  const adInterval = Math.floor(filteredResults.length / (ads.length + 1));

  filteredResults.forEach((result, index) => {
    resultsWithAds.push(result);
    if ((index + 1) % adInterval === 0 && ads[Math.floor(index / adInterval)]) {
      resultsWithAds.push({ isAd: true, adData: ads[Math.floor(index / adInterval)] });
    }
  });

  return (
    <>
      {error && <motion.p className="error" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>{error}</motion.p>}
      {isLoading && (
        <motion.div 
          className="loading-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <img src={loadingImage} alt="Loading" className="loading-image" />
          <motion.p className="loading-text">{t('searching')}</motion.p>
        </motion.div>
      )}
      <AnimatePresence mode="wait">
        {!isLoading && filteredResults.length > 0 && (
          <motion.div
            key={currentPage}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
            className="search-results"
          >
            <p>{t('resultsFound', totalHits)}</p>
            <motion.div variants={containerVariants} initial="hidden" animate="visible">
              {resultsWithAds.map((item, index) => 
                item.isAd ? (
                  <Ad key={`ad-${index}`} adData={item.adData} />
                ) : (
                  renderResult(item, false, settings, toggleStar, starredResults, mainImages, t, openFullSizeImage, starredFolders, moveToFolder)
                )
              )}
            </motion.div>
            <div className="pagination">
              <motion.button 
                onClick={goToPreviousPage} 
                disabled={currentPage === 0}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {t('previous')}
              </motion.button>
              <span>{t('page', currentPage + 1)}</span>
              <motion.button 
                onClick={goToNextPage} 
                disabled={(currentPage + 1) * settings.resultsPerPage >= totalHits}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {t('next')}
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {!isLoading && filteredResults.length === 0 && (
        <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}>{t('noResults')}
        <img src={noresultImage} alt="Loading" className="loading-image" />
        </motion.p>
      )}
    </>
  );
}