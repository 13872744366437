// src/translations.js

export const en = {
      searchPlaceholder: "Sniffing out something fur-tastic...",
      resultsFound: "Found about {0} results",
      noResults: "Uh-oh, no results in this fur-tastic world.",
      loading: "Loading...",
      previousButton: "Previous",
      nextButton: "Next",
      page: "Page {0}",
      starredResults: "Starred Results",
      noStarredResults: "No starred results yet.",
      searchHistory: "Search History",
      noSearchHistory: "No search history yet.",
      clearHistory: "Clear History",
      deleteFolder: "Delete Folder",
      deleteFolderConfirm: 'Are you sure you want to delete the folder "{0}" and all its contents?',
      yesDelete: "Yes, Delete",
      cancel: "Cancel",
      renameFolder: "Rename Folder",
      rename: "Rename",
      newFolderName: "New folder name",
      reverseImageSearch: "Reverse Image Search",
      uploadImage: "Upload Image",
      changeImage: "Change Image",
      search: "Search",
      searchingVisualRealm: "Searching...",
      searchResults: "Search Results",
      score: "Score: {0}%",
      viewOriginal: "View Original",
      settings: "Settings",
      displaySettings: "Display Settings",
      showImages: "Show Images",
      showImagesDesc: "Display thumbnail images in search results.",
      nsfwEffect: "NSFW Effect",
      nsfwEffectDesc: "Apply a visual effect to potentially sensitive content.",
      colorTheme: "Color Theme",
      colorThemeDesc: "Choose between dark and bright color schemes.",
      searchSettings: "Search Settings",
      resultsPerPage: "Results per page:",
      resultsPerPageDesc: "Set the number of search results displayed on each page.",
      nsfwFilter: "NSFW Filter",
      nsfwFilterDesc: "Filter out potentially sensitive content from search results.",
      saveSettings: "Save Settings",
      disableNsfwFilter: 'Disable NSFW Filter',
      enableNsfwFilter: 'Enable NSFW Filter',
      previous: 'Previous',
      next: 'Next',
      noDescription: " ",
      searching: "Searching the furry multiverse!",
      viewStarredResults: "View Starred Results",
      folders: "Folders",
      unorganizedStars: "Unorganized Stars",
      noFolder: "No Folder",
      createFolder: "Create Folder",
      viewSearchHistory: "View Search History",
      hideImages: "Hide Images",
      galleryMode: "Gallery Mode",
      hideNsfwEffect: "Hide NSFW Effect",
      switchToBrightTheme: "Switch to Bright Theme",
      switchToDarkTheme: "Switch to Dark Theme",
      searchEngineSettings: "Setting",
      changeLanguage: "Language",
      theme: "Theme",
      dark: "Dark",
      bright: "Bright",
      poweredByFluffy: "Powered By Fluffy.xyz made by NoppesTheFolf",
      exportData: "Export Data",
      importData: "Import Data",
      importWarning: "Warning: Importing data will overwrite your current starred results and folders. Do you want to continue?",
      importSuccess: "Data imported successfully!",
      importError: "Error importing data. Please check the file and try again.",
      noImagesAvailable: "No Images Available",
      allRightsReserved: 'All rights reserved.',
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      tos_pp_date: 'Last Update: Aug 8, 2024',
      tosContent: '1. Acceptance of Terms',
      tosContent1: 'By accessing or using SearchFur (the "Service"), you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not access the Service.',
      tosContent2: '2. Description of Service',
      tosContent3: 'SearchFur is a search engine that allows users to search the internet for information. The Service may store cookies on users" devices to maintain search history and Starred Results.',
      tosContent4: '3. User Responsibilities',
      tosContent5: '3.1. You agree to use the Service only for lawful purposes and in accordance with these Terms.',
      tosContent6: '3.2. You are responsible for maintaining the confidentiality of any personal information you provide while using the Service.',
      tosContent7: '4. Intellectual Property',
      tosContent8: '4.1. The Service and its original content, features, and functionality are and will remain the exclusive property of SearchFur and its licensors.',
      tosContent9: '4.2. Users retain all rights to the content of their searches.',
      tosContent10: '5. Advertising',
      tosContent11: '5.1. SearchFur may display advertisements as part of the Service. These ads help support and maintain the free aspects of our Service.',
      tosContent12: '5.2. We do not use personal data to target ads. All ads are contextual based on search queries or general categories.',
      tosContent13: '6. Pro Features',
      tosContent14: '6.1. SearchFur may offer premium features in the future as part of a paid subscription service ("Pro Features").',
      tosContent15: '6.2. Terms for Pro Features will be provided separately if and when such features become available.',
      tosContent16: '6.3. Free features will remain available to all users.',
      tosContent17: '7. Reverse Image Search',
      tosContent18: '7.1. SearchFur uses Fluffle.xyz for reverse image search functionality. By using this feature, you agree to Fluffles terms of service and privacy policy.',
      tosContent19: '7.2. When you use the reverse image search feature, your image will be temporarily stored on Fluffle"s servers for processing. The image is deleted immediately after processing, except when you create a permanent link to the search result.',
      tosContent20: '7.3. SearchFur is not responsible for the privacy practices or content of Fluffle.xyz. We encourage you to review Fluffle"s privacy policy for more information.',
      tosContent21: '8. Limitation of Liability',
      tosContent22: '8.1. SearchFur shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your access to or use of, or inability to access or use, the Service.',
      tosContent23: '8. Changes to Terms',
      tosContent24: '8.1. We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days notice prior to any new terms taking effect.',
      privacyContent: '1. Introduction',
      privacyContent1: 'This Privacy Policy describes how SearchFur ("we", "our", or "us") collects, uses, and shares information about you when you use our search engine service. We are committed to protecting your privacy and ensuring you have a positive experience on our website.',
      privacyContent2: '2. Information We Collect',
      privacyContent3: '2.1. Search Queries: We collect and store the search queries you enter into SearchFur. This information is collected anonymously and cannot be linked to individual users.',
      privacyContent4: '2.2. Cookies: We use cookies to store your search history and Starred Results on your device. This information remains on your device and is not collected or accessed by SearchFur.',
      privacyContent5: '3. How We Use Information',
      privacyContent6: '3.1. We use the anonymized search queries to improve our search engine service and provide more relevant search results.',
      privacyContent7: '3.2. We do not use the information stored in cookies (search history and Starred Results) as this information remains solely on your device.',
      privacyContent8: '4. Advertising',
      privacyContent9: '4.1. We may display advertisements as part of our Service. These ads are based on the context of your search queries or general categories.',
      privacyContent10: '4.2. We do not collect or use personal information for ad targeting. All ads are served based on the content of your searches or general topics, not on user profiles or personal data.',
      privacyContent11: '5. Reverse Image Search',
      privacyContent12: '5.1. For reverse image search functionality, SearchFur uses Fluffle.xyz, a third-party service.',
      privacyContent13: '5.2. When you use the reverse image search feature: 1. Your image is temporarily stored on Fluffle"s servers for processing. 2. The image is deleted immediately after processing, except when you create a permanent link to the search result.3. Fluffle creates a fingerprint of your image for comparison but does not store this fingerprint. 4. Fluffle logs certain non-personal information about your request for performance and security purposes.',
      privacyContent14: '5.3. Fluffle uses Cloudflare"s web analytics platform, which does not collect personal information, track browsing behavior, or use cookies.',
      privacyContent15: '5.4. For more detailed information, please refer to Fluffle"s privacy policy.',
      privacyContent16: '6. Pro Features',
      privacyContent17: '6.1. If we introduce Pro Features in the future, we may collect additional information necessary to provide these services, such as account information and payment details. 6.2. Any additional data collection related to Pro Features will be clearly communicated before implementation, and users will have the choice to opt-in to these features.',
      privacyContent18: '7. Information Sharing and Disclosure',
      privacyContent19: '7.1. We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties. 7.2. We may disclose information when required by law or to protect our rights, privacy, safety, property, or that of others.',
      privacyContent20: '8. Data Retention',
      privacyContent21: 'We retain anonymized search queries for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.',
      privacyContent22: '9. Your Choices',
      privacyContent23: '9.1. You can choose to disable cookies through your browser settings. However, this may affect the functionality of certain features like search history and Starred Results.',
      privacyContent24: '9.2. As we do not collect personally identifiable information, we cannot provide individual access to or deletion of search data.',
      privacyContent25: '10. Children"s Privacy',
      privacyContent26: 'Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us.',
      privacyContent27: '11. Changes to This Privacy Policy',
      privacyContent28: 'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.',
      privacyContent29: '12. Security',
      privacyContent30: 'We are committed to ensuring that your information is secure. We have implemented suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.',
      delete: 'Delete',
      availableinpro: 'Changing Results per page is ONLY avaliable for PRO',
      madebykeyfox: 'Made by Keyfox/Jianhu with ❤️',
}