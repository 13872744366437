export const zh = {
  searchPlaceholder: "嗅探毛茸茸的奇妙事物...",
  resultsFound: "找到约 {0} 个结果",
  noResults: "哎呀，在这个毛茸茸的世界里没有找到结果。",
  loading: "加载中...",
  previousButton: "上一页",
  nextButton: "下一页",
  page: "第 {0} 页",
  starredResults: "已收藏结果",
  noStarredResults: "暂无收藏结果。",
  searchHistory: "搜索历史",
  noSearchHistory: "暂无搜索历史。",
  clearHistory: "清除历史",
  deleteFolder: "删除文件夹",
  deleteFolderConfirm: '您确定要删除文件夹 "{0}" 及其所有内容吗？',
  yesDelete: "是的，删除",
  cancel: "取消",
  renameFolder: "重命名文件夹",
  rename: "重命名",
  newFolderName: "新文件夹名称",
  reverseImageSearch: "以图搜图",
  uploadImage: "上传图片",
  changeImage: "更换图片",
  search: "搜索",
  searchingVisualRealm: "搜索中...",
  searchResults: "搜索结果",
  score: "相似度：{0}%",
  viewOriginal: "查看原图",
  settings: "设置",
  displaySettings: "显示设置",
  showImages: "显示图片",
  showImagesDesc: "在搜索结果中显示缩略图。",
  nsfwEffect: "NSFW 效果",
  nsfwEffectDesc: "对可能敏感的内容应用视觉效果。",
  colorTheme: "颜色主题",
  colorThemeDesc: "选择深色或亮色主题。",
  searchSettings: "搜索设置",
  resultsPerPage: "每页结果数：",
  resultsPerPageDesc: "设置每页显示的搜索结果数量。",
  nsfwFilter: "NSFW 过滤器",
  nsfwFilterDesc: "从搜索结果中过滤掉可能敏感的内容。",
  saveSettings: "保存设置",
  disableNsfwFilter: '禁用 NSFW 过滤器',
  enableNsfwFilter: '启用 NSFW 过滤器',
  previous: '上一页',
  next: '下一页',
  noDescription: " ",
  searching: "正在搜索毛茸茸的多元宇宙！",
  viewStarredResults: "查看已收藏结果",
  folders: "文件夹",
  unorganizedStars: "未整理的收藏",
  noFolder: "无文件夹",
  createFolder: "创建文件夹",
  viewSearchHistory: "查看搜索历史",
  hideImages: "隐藏图片",
  galleryMode: "图库模式",
  hideNsfwEffect: "隐藏 NSFW 效果",
  switchToBrightTheme: "切换到亮色主题",
  switchToDarkTheme: "切换到深色主题",
  searchEngineSettings: "设置",
  changeLanguage: "语言",
  theme: "主题",
  dark: "深色",
  bright: "亮色",
  poweredByFluffy: "由 NoppesTheFolf 开发的 Fluffy.xyz 提供支持",
  exportData: "导出数据",
  importData: "导入数据",
  importWarning: "警告：导入数据将覆盖您当前的收藏结果和文件夹。是否继续？",
  importSuccess: "数据导入成功！",
  importError: "导入数据时出错。请检查文件后重试。",
  noImagesAvailable: "暂无可用图片",
  allRightsReserved: '保留所有权利。',
  termsOfService: '服务条款',
  privacyPolicy: '隐私政策',
  tos_pp_date: '最后更新：2024年8月8日',
  tosContent: '1. 接受条款',
  tosContent1: '通过访问或使用 SearchFur（"服务"），您同意受这些服务条款（"条款"）的约束。如果您不同意这些条款的任何部分，则不得使用本服务。',
  tosContent2: '2. 服务描述',
  tosContent3: 'SearchFur 是一个允许用户在互联网上搜索信息的搜索引擎。该服务可能会在用户设备上存储 cookie 以维护搜索历史和收藏结果。',
  tosContent4: '3. 用户责任',
  tosContent5: '3.1. 您同意仅将本服务用于合法目的，并遵守这些条款。',
  tosContent6: '3.2. 您有责任保护在使用服务时提供的任何个人信息的机密性。',
  tosContent7: '4. 知识产权',
  tosContent8: '4.1. 本服务及其原创内容、功能和功能性现在和将来都是 SearchFur 及其许可方的专有财产。',
  tosContent9: '4.2. 用户保留其搜索内容的所有权利。',
  tosContent10: '5. 广告',
  tosContent11: '5.1. SearchFur 可能会在服务中显示广告。这些广告有助于支持和维护我们服务的免费部分。',
  tosContent12: '5.2. 我们不使用个人数据来投放广告。所有广告都基于搜索查询或一般类别进行上下文投放。',
  tosContent13: '6. 专业功能',
  tosContent14: '6.1. SearchFur 可能会在未来提供付费订阅服务的高级功能（"专业功能"）。',
  tosContent15: '6.2. 如果这些功能可用，将单独提供专业功能的条款。',
  tosContent16: '6.3. 免费功能将继续向所有用户开放。',
  tosContent17: '7. 以图搜图',
  tosContent18: '7.1. SearchFur 使用 Fluffle.xyz 提供以图搜图功能。使用此功能即表示您同意 Fluffle 的服务条款和隐私政策。',
  tosContent19: '7.2. 当您使用以图搜图功能时，您的图片将暂时存储在 Fluffle 的服务器上进行处理。处理完成后图片会立即删除，除非您创建了搜索结果的永久链接。',
  tosContent20: '7.3. SearchFur 不对 Fluffle.xyz 的隐私做法或内容负责。我们建议您查看 Fluffle 的隐私政策以获取更多信息。',
  tosContent21: '8. 责任限制',
  tosContent22: '8.1. SearchFur 不对因您访问或使用、或无法访问或使用本服务而导致的任何间接、偶然、特殊、后果性或惩罚性损害承担责任。',
  tosContent23: '9. 条款变更',
  tosContent24: '9.1. 我们保留随时修改或替换这些条款的权利。如果修订是重大的，我们将在任何新条款生效前至少提供 30 天的通知。',
  privacyContent: '1. 简介',
  privacyContent1: '本隐私政策描述了 SearchFur（"我们"、"我们的"或"我们"）在您使用我们的搜索引擎服务时如何收集、使用和共享您的信息。我们致力于保护您的隐私并确保您在我们的网站上有良好的体验。',
  privacyContent2: '2. 我们收集的信息',
  privacyContent3: '2.1. 搜索查询：我们收集并存储您输入 SearchFur 的搜索查询。这些信息是匿名收集的，无法与单个用户关联。',
  privacyContent4: '2.2. Cookie：我们使用 cookie 在您的设备上存储您的搜索历史和收藏结果。这些信息保留在您的设备上，不会被 SearchFur 收集或访问。',
  privacyContent5: '3. 我们如何使用信息',
  privacyContent6: '3.1. 我们使用匿名化的搜索查询来改进我们的搜索引擎服务并提供更相关的搜索结果。',
  privacyContent7: '3.2. 我们不使用存储在 cookie 中的信息（搜索历史和收藏结果），因为这些信息仅保留在您的设备上。',
  privacyContent8: '4. 广告',
  privacyContent9: '4.1. 我们可能会在我们的服务中显示广告。这些广告基于您的搜索查询上下文或一般类别。',
  privacyContent10: '4.2. 我们不收集或使用个人信息进行广告定位。所有广告都基于您的搜索内容或一般主题，而不是用户档案或个人数据。',
  privacyContent11: '5. 以图搜图',
  privacyContent12: '5.1. SearchFur 使用第三方服务 Fluffle.xyz 提供以图搜图功能。',
  privacyContent13: '5.2. 当您使用以图搜图功能时：1. 您的图片会暂时存储在 Fluffle 的服务器上进行处理。2. 处理完成后图片会立即删除，除非您创建了搜索结果的永久链接。3. Fluffle 会创建您图片的指纹用于比较，但不会存储这个指纹。4. Fluffle 会记录某些非个人信息，用于性能和安全目的。',
  privacyContent14: '5.3. Fluffle 使用 Cloudflare 的网络分析平台，该平台不收集个人信息，不跟踪浏览行为，也不使用 cookie。',
  privacyContent15: '5.4. 有关更详细的信息，请参阅 Fluffle 的隐私政策。',
  privacyContent16: '6. 专业功能',
  privacyContent17: '6.1. 如果我们将来引入专业功能，我们可能会收集提供这些服务所需的额外信息，例如帐户信息和支付详情。6.2. 与专业功能相关的任何额外数据收集都将在实施前明确传达，用户将可以选择加入这些功能。',
  privacyContent18: '7. 信息共享和披露',
  privacyContent19: '7.1. 我们不会出售、交易或以其他方式将您的个人身份信息转让给外部各方。7.2. 我们可能会在法律要求或为保护我们或他人的权利、隐私、安全或财产时披露信息。',
  privacyContent20: '8. 数据保留',
  privacyContent21: '我们保留匿名化搜索查询的时间不会超过本隐私政策中概述的目的所需的时间，除非法律要求更长的保留期。',
  privacyContent22: '9. 您的选择',
  privacyContent23: '9.1. 您可以选择通过浏览器设置禁用 cookie。但是，这可能会影响某些功能的功能，如搜索历史和收藏结果。',
  privacyContent24: '9.2. 由于我们不收集个人身份信息，我们无法提供个人搜索数据的访问或删除。',
  privacyContent25: '10. 儿童隐私',
  privacyContent26: '我们的服务不面向 13 岁以下的任何人。我们不会故意收集 13 岁以下儿童的个人身份信息。如果您是父母或监护人，并且您意识到您的孩子向我们提供了个人信息，请联系我们。',
  privacyContent27: '11. 本隐私政策的变更',
  privacyContent28: '我们可能会不时更新我们的隐私政策。我们会通过在本页面上发布新的隐私政策并更新本隐私政策顶部的"最后更新"日期来通知您任何变更。',
  privacyContent29: '12. 安全',
  privacyContent30: '我们致力于确保您的信息安全。我们已实施适当的物理、电子和管理程序来保护和保障我们在线收集的信息。',
  delete: '删除',
  availableinpro: '更改每页结果数仅适用于专业版',
  madebykeyfox: '由 Keyfox/Jianhu 用❤️制作'
}