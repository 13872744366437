import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { search } from '../utils/api';
import { useSettings } from '../contexts/SettingsContext';
import { useSearchHistory } from './useSearchHistory';

export function useSearch() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalHits, setTotalHits] = useState(0);
  const [selectedWebsite, setSelectedWebsite] = useState('*');
  const { settings } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();
  const { addToHistory } = useSearchHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlQuery = params.get('q');
    const urlSite = params.get('site');
    const urlPage = params.get('page');
    if (urlQuery) {
      setQuery(urlQuery);
    }
    if (urlSite) {
      setSelectedWebsite(urlSite);
    }
    if (urlPage) {
      setCurrentPage(parseInt(urlPage, 10));
    }
  }, [location]);

  const handleSearch = useCallback(async (e, page = currentPage, searchQuery = query, website = selectedWebsite) => {
    e && e.preventDefault();
    if (!searchQuery) return;

    setIsLoading(true);
    setError(null);
    setCurrentPage(page);

    navigate(`/?q=${encodeURIComponent(searchQuery)}&site=${website}&page=${page}`);
    addToHistory(searchQuery);

    try {
      const data = await search(searchQuery, page, settings.resultsPerPage, website);
      if (data && data.hits) {
        setResults(data.hits.hits);
        setTotalHits(data.hits.total.value);
        return data.hits.hits;
      } else {
        setError('Unexpected response format from Elasticsearch');
      }
    } catch (err) {
      console.error('An error occurred while searching:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [query, selectedWebsite, currentPage, settings.resultsPerPage, navigate, addToHistory]);

  const goToNextPage = useCallback(() => {
    const nextPage = currentPage + 1;
    if (nextPage * settings.resultsPerPage < totalHits) {
      handleSearch(null, nextPage);
    }
  }, [currentPage, settings.resultsPerPage, totalHits, handleSearch]);

  const goToPreviousPage = useCallback(() => {
    if (currentPage > 0) {
      handleSearch(null, currentPage - 1);
    }
  }, [currentPage, handleSearch]);

  const changeWebsite = useCallback((website) => {
    setSelectedWebsite(website);
    handleSearch(null, 0, query, website);
  }, [query, handleSearch]);

  return {
    query,
    setQuery,
    results,
    isLoading,
    error,
    currentPage,
    totalHits,
    handleSearch,
    goToNextPage,
    goToPreviousPage,
    selectedWebsite,
    changeWebsite
  };
}