import React, { useState, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { renderResult } from '../SearchResults';
import { saveAs } from 'file-saver';

export function StarredPopup({
  starredResults,
  starredFolders,
  createFolder,
  moveToFolder,
  setShowDeletePopup,
  setShowRenamePopup,
  setFolderToModify,
  setNewFolderName,
  closePopup,
  t,
  settings,
  toggleStar,
  mainImages,
  openFullSizeImage,
  updateStarredData
}) {
  const [activeFolder, setActiveFolder] = useState(null);
  const [newFolderNameInput, setNewFolderNameInput] = useState('');
  const fileInputRef = useRef(null);

  const exportStarredData = () => {
    const data = {
      starredResults: starredResults,
      starredFolders: starredFolders
    };
    const blob = new Blob([JSON.stringify(data)], {type: "application/json;charset=utf-8"});
    saveAs(blob, "starred_results_export.json");
  };

  const importStarredData = useCallback((event) => {
    console.log('Import function called');
    const file = event.target.files[0];
    if (file) {
      console.log('File selected:', file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = JSON.parse(e.target.result);
          console.log('Parsed data:', data);
          if (window.confirm(t('importWarning'))) {
            if (typeof updateStarredData !== 'function') {
              console.error('updateStarredData is not a function');
              alert('An error occurred while importing data. Please try again.');
              return;
            }
            updateStarredData(data.starredResults || [], data.starredFolders || {});
            console.log('Data imported successfully');
            alert(t('importSuccess'));
            window.location.reload(); // Refresh the page after successful import
          }
        } catch (error) {
          console.error('Error parsing imported data:', error);
          alert(t('importError'));
        }
      };
      reader.readAsText(file);
    }
  }, [updateStarredData, t]);

  const handleImportClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const renderStarredContent = () => {
    if (activeFolder) {
      const folderItems = starredFolders[activeFolder] || [];
      const folderResults = starredResults.filter(hit => folderItems.includes(hit._id));
      return (
        <div className="folder-view">
          <h3 className="folder-title">{activeFolder}</h3>
          <button className="back-button" onClick={() => setActiveFolder(null)}>
            ← {t('backToAllFolders')}
          </button>
          <div className="folder-results">
            {folderResults.length > 0 ? 
              folderResults.map(hit => renderResult(hit, true, settings, toggleStar, starredResults, mainImages, t, openFullSizeImage, starredFolders, moveToFolder)) :
              <p className="no-results">{t('emptyFolder')}</p>
            }
          </div>
        </div>
      );
    } else {
      return (
        <div className="folders-view">
          <h3 className="section-title">{t('folders')}</h3>
          <div className="folders-grid">
            {Object.keys(starredFolders).map(folderName => (
              <div key={folderName} className="folder-item">
                <div className="folder-icon">📁</div>
                <span className="folder-name" onClick={() => setActiveFolder(folderName)}>{folderName}</span>
                <div className="folder-actions">
                  <button className="rename-button" onClick={() => {
                    setFolderToModify(folderName);
                    setNewFolderName(folderName);
                    setShowRenamePopup(true);
                  }}>{t('rename')}</button>
                  <button className="delete-button" onClick={() => {
                    setFolderToModify(folderName);
                    setShowDeletePopup(true);
                  }}>{t('delete')}</button>
                </div>
              </div>
            ))}
          </div>
          <div className="new-folder-form">
            <input
              type="text"
              value={newFolderNameInput}
              onChange={(e) => setNewFolderNameInput(e.target.value)}
              placeholder={t('newFolderName')}
              className="new-folder-input"
            />
            <button className="create-folder-button" onClick={() => {
              createFolder(newFolderNameInput);
              setNewFolderNameInput('');
            }}>{t('createFolder')}</button>
          </div>
          <h3 className="section-title">{t('unorganizedStars')}</h3>
          <div className="unorganized-results">
            {starredResults.filter(hit => !Object.values(starredFolders).flat().includes(hit._id)).map(hit => 
              renderResult(hit, true, settings, toggleStar, starredResults, mainImages, t, openFullSizeImage, starredFolders, moveToFolder)
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <motion.div
      className="popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2>{t('starredResults')}</h2>
        <motion.button 
          className="close-popup" 
          onClick={closePopup}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          ×
        </motion.button>
        <div className="action-buttons">
          <motion.button
            className="action-button"
            onClick={exportStarredData}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {t('exportData')}
          </motion.button>
          <motion.button
            className="action-button"
            onClick={handleImportClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {t('importData')}
          </motion.button>
          <input
            ref={fileInputRef}
            type="file"
            accept=".json"
            style={{ display: 'none' }}
            onChange={importStarredData}
          />
        </div>
        {starredResults.length > 0 ? renderStarredContent() : <p>{t('noStarredResults')}</p>}
      </motion.div>
    </motion.div>
  );
}