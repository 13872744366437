import React from 'react';
import { motion } from 'framer-motion';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

export function HistoryPopup({ searchHistory, clearHistory, handleSearch, closePopup, t }) {
  return (
    <motion.div
      className="popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2>{t('searchHistory')}</h2>
        <motion.button 
          className="close-popup" 
          onClick={closePopup}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          ×
        </motion.button>
        {searchHistory.length > 0 ? (
          <>
            <motion.ul className="history-list" variants={containerVariants} initial="hidden" animate="visible">
              {searchHistory.map((item, index) => (
                <motion.li key={index} variants={itemVariants}>
                  <motion.button 
                    className="history-item" 
                    onClick={() => {
                      handleSearch(null, 0, item);
                      closePopup();
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {item}
                  </motion.button>
                </motion.li>
              ))}
            </motion.ul>
            <motion.button 
              className="clear-history" 
              onClick={clearHistory}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('clearHistory')}
            </motion.button>
          </>
        ) : (
          <p>{t('noSearchHistory')}</p>
        )}
      </motion.div>
    </motion.div>
  );
}