export const da = {
    searchPlaceholder: "Snuser efter noget pelsagtigt...",
    resultsFound: "Fandt omkring {0} resultater",
    noResults: "Ups, ingen resultater i denne pelsede verden.",
    loading: "Indlæser...",
    previousButton: "Forrige",
    nextButton: "Næste",
    page: "Side {0}",
    starredResults: "Stjernemarkerede resultater",
    noStarredResults: "Ingen stjernemarkerede resultater endnu.",
    searchHistory: "Søgehistorik",
    noSearchHistory: "Ingen søgehistorik endnu.",
    clearHistory: "Ryd historik",
    deleteFolder: "Slet mappe",
    deleteFolderConfirm: 'Er du sikker på, at du vil slette mappen "{0}" og alt dens indhold?',
    yesDelete: "Ja, slet",
    cancel: "Annuller",
    renameFolder: "Omdøb mappe",
    rename: "Omdøb",
    newFolderName: "Nyt mappenavn",
    reverseImageSearch: "Omvendt billedsøgning",
    uploadImage: "Upload billede",
    changeImage: "Skift billede",
    search: "Søg",
    searchingVisualRealm: "Søger...",
    searchResults: "Søgeresultater",
    score: "Score: {0}%",
    viewOriginal: "Se original",
    settings: "Indstillinger",
    displaySettings: "Visningsindstillinger",
    showImages: "Vis billeder",
    showImagesDesc: "Vis miniaturebilleder i søgeresultater.",
    nsfwEffect: "NSFW-effekt",
    nsfwEffectDesc: "Anvend en visuel effekt på potentielt følsomt indhold.",
    colorTheme: "Farvetema",
    colorThemeDesc: "Vælg mellem mørke og lyse farveskemaer.",
    searchSettings: "Søgeindstillinger",
    resultsPerPage: "Resultater pr. side:",
    resultsPerPageDesc: "Indstil antallet af søgeresultater, der vises på hver side.",
    nsfwFilter: "NSFW-filter",
    nsfwFilterDesc: "Filtrer potentielt følsomt indhold fra søgeresultater.",
    saveSettings: "Gem indstillinger",
    disableNsfwFilter: 'Deaktiver NSFW-filter',
    enableNsfwFilter: 'Aktiver NSFW-filter',
    previous: 'Forrige',
    next: 'Næste',
    noDescription: " ",
    searching: "Søger i det pelsede multivers!",
    viewStarredResults: "Se stjernemarkerede resultater",
    folders: "Mapper",
    unorganizedStars: "Uorganiserede stjerner",
    noFolder: "Ingen mappe",
    createFolder: "Opret mappe",
    viewSearchHistory: "Se søgehistorik",
    hideImages: "Skjul billeder",
    galleryMode: "Galleritilstand",
    hideNsfwEffect: "Skjul NSFW-effekt",
    switchToBrightTheme: "Skift til lyst tema",
    switchToDarkTheme: "Skift til mørkt tema",
    searchEngineSettings: "Indstillinger",
    changeLanguage: "Sprog",
    theme: "Tema",
    dark: "Mørkt",
    bright: "Lyst",
    poweredByFluffy: "Drevet af Fluffy.xyz skabt af NoppesTheFolf",
    exportData: "Eksportér data",
    importData: "Importér data",
    importWarning: "Advarsel: Import af data vil overskrive dine nuværende stjernemarkerede resultater og mapper. Vil du fortsætte?",
    importSuccess: "Data importeret med succes!",
    importError: "Fejl ved import af data. Tjek venligst filen og prøv igen.",
    noImagesAvailable: "Ingen billeder tilgængelige",
    allRightsReserved: 'Alle rettigheder forbeholdes.',
    termsOfService: 'Servicevilkår',
    privacyPolicy: 'Privatlivspolitik',
    tos_pp_date: 'Sidst opdateret: 8. august 2024',
    tosContent: '1. Acceptér vilkår',
    tosContent1: 'Ved at få adgang til eller bruge SearchFur ("Tjenesten") accepterer du at være bundet af disse Servicevilkår ("Vilkår"). Hvis du er uenig i nogen del af vilkårene, må du ikke få adgang til Tjenesten.',
    tosContent2: '2. Beskrivelse af tjenesten',
    tosContent3: 'SearchFur er en søgemaskine, der giver brugerne mulighed for at søge efter information på internettet. Tjenesten kan gemme cookies på brugernes enheder for at opretholde søgehistorik og Stjernemarkerede Resultater.',
    tosContent4: '3. Brugeransvar',
    tosContent5: '3.1. Du accepterer kun at bruge Tjenesten til lovlige formål og i overensstemmelse med disse Vilkår.',
    tosContent6: '3.2. Du er ansvarlig for at opretholde fortroligheden af enhver personlig information, du giver, mens du bruger Tjenesten.',
    tosContent7: '4. Intellektuel ejendomsret',
    tosContent8: '4.1. Tjenesten og dens originale indhold, funktioner og funktionalitet er og vil forblive SearchFurs og dets licensgiveres eksklusive ejendom.',
    tosContent9: '4.2. Brugere beholder alle rettigheder til indholdet af deres søgninger.',
    tosContent10: '5. Annoncering',
    tosContent11: '5.1. SearchFur kan vise annoncer som en del af Tjenesten. Disse annoncer hjælper med at støtte og vedligeholde de gratis aspekter af vores Tjeneste.',
    tosContent12: '5.2. Vi bruger ikke personlige data til at målrette annoncer. Alle annoncer er kontekstuelle baseret på søgeforespørgsler eller generelle kategorier.',
    tosContent13: '6. Pro-funktioner',
    tosContent14: '6.1. SearchFur kan tilbyde premium-funktioner i fremtiden som en del af en betalt abonnementstjeneste ("Pro-funktioner").',
    tosContent15: '6.2. Vilkår for Pro-funktioner vil blive leveret separat, hvis og når sådanne funktioner bliver tilgængelige.',
    tosContent16: '6.3. Gratis funktioner vil forblive tilgængelige for alle brugere.',
    tosContent17: '7. Omvendt billedsøgning',
    tosContent18: '7.1. SearchFur bruger Fluffle.xyz til omvendt billedsøgningsfunktionalitet. Ved at bruge denne funktion accepterer du Fluffles servicevilkår og privatlivspolitik.',
    tosContent19: '7.2. Når du bruger den omvendte billedsøgningsfunktion, vil dit billede midlertidigt blive gemt på Fluffles servere til behandling. Billedet slettes straks efter behandling, undtagen når du opretter et permanent link til søgeresultatet.',
    tosContent20: '7.3. SearchFur er ikke ansvarlig for Fluffle.xyzs privatlivspraksis eller indhold. Vi opfordrer dig til at gennemgå Fluffles privatlivspolitik for mere information.',
    tosContent21: '8. Ansvarsbegrænsning',
    tosContent22: '8.1. SearchFur er ikke ansvarlig for nogen indirekte, tilfældige, særlige, følgeskader eller pønale skader som følge af din adgang til eller brug af, eller manglende evne til at få adgang til eller bruge, Tjenesten.',
    tosContent23: '9. Ændringer i vilkår',
    tosContent24: '9.1. Vi forbeholder os retten til at ændre eller erstatte disse Vilkår til enhver tid. Hvis en revision er væsentlig, vil vi give mindst 30 dages varsel, før nye vilkår træder i kraft.',
    privacyContent: '1. Introduktion',
    privacyContent1: 'Denne Privatlivspolitik beskriver, hvordan SearchFur ("vi", "vores" eller "os") indsamler, bruger og deler information om dig, når du bruger vores søgemaskinetjeneste. Vi er forpligtet til at beskytte dit privatliv og sikre, at du har en positiv oplevelse på vores hjemmeside.',
    privacyContent2: '2. Information vi indsamler',
    privacyContent3: '2.1. Søgeforespørgsler: Vi indsamler og gemmer de søgeforespørgsler, du indtaster i SearchFur. Denne information indsamles anonymt og kan ikke linkes til individuelle brugere.',
    privacyContent4: '2.2. Cookies: Vi bruger cookies til at gemme din søgehistorik og Stjernemarkerede Resultater på din enhed. Denne information forbliver på din enhed og indsamles eller tilgås ikke af SearchFur.',
    privacyContent5: '3. Sådan bruger vi information',
    privacyContent6: '3.1. Vi bruger de anonymiserede søgeforespørgsler til at forbedre vores søgemaskinetjeneste og levere mere relevante søgeresultater.',
    privacyContent7: '3.2. Vi bruger ikke den information, der er gemt i cookies (søgehistorik og Stjernemarkerede Resultater), da denne information forbliver udelukkende på din enhed.',
    privacyContent8: '4. Annoncering',
    privacyContent9: '4.1. Vi kan vise annoncer som en del af vores Tjeneste. Disse annoncer er baseret på konteksten af dine søgeforespørgsler eller generelle kategorier.',
    privacyContent10: '4.2. Vi indsamler eller bruger ikke personlig information til annoncemålretning. Alle annoncer vises baseret på indholdet af dine søgninger eller generelle emner, ikke på brugerprofiler eller personlige data.',
    privacyContent11: '5. Omvendt billedsøgning',
    privacyContent12: '5.1. For omvendt billedsøgningsfunktionalitet bruger SearchFur Fluffle.xyz, en tredjepartstjeneste.',
    privacyContent13: '5.2. Når du bruger den omvendte billedsøgningsfunktion: 1. Dit billede gemmes midlertidigt på Fluffles servere til behandling. 2. Billedet slettes straks efter behandling, undtagen når du opretter et permanent link til søgeresultatet. 3. Fluffle opretter et fingeraftryk af dit billede til sammenligning, men gemmer ikke dette fingeraftryk. 4. Fluffle logger visse ikke-personlige oplysninger om din anmodning til ydelses- og sikkerhedsformål.',
    privacyContent14: '5.3. Fluffle bruger Cloudflares webanalyseplattform, som ikke indsamler personlige oplysninger, ikke sporer browsingadfærd og ikke bruger cookies.',
    privacyContent15: '5.4. For mere detaljerede oplysninger henvises til Fluffles privatlivspolitik.',
    privacyContent16: '6. Pro-funktioner',
    privacyContent17: '6.1. Hvis vi indfører Pro-funktioner i fremtiden, kan vi indsamle yderligere information nødvendig for at levere disse tjenester, såsom kontooplysninger og betalingsdetaljer. 6.2. Enhver yderligere dataindsamling relateret til Pro-funktioner vil blive klart kommunikeret før implementering, og brugere vil have mulighed for at tilmelde sig disse funktioner.',
    privacyContent18: '7. Informationsdeling og -offentliggørelse',
    privacyContent19: '7.1. Vi sælger, handler eller overfører på anden måde ikke dine personligt identificerbare oplysninger til udenforstående parter. 7.2. Vi kan offentliggøre information, når det kræves ved lov eller for at beskytte vores rettigheder, privatliv, sikkerhed, ejendom eller andres.',
    privacyContent20: '8. Dataopbevaring',
    privacyContent21: 'Vi opbevarer anonymiserede søgeforespørgsler så længe det er nødvendigt for at opfylde formålene beskrevet i denne Privatlivspolitik, medmindre en længere opbevaringsperiode er påkrævet eller tilladt ved lov.',
    privacyContent22: '9. Dine valg',
    privacyContent23: '9.1. Du kan vælge at deaktivere cookies gennem dine browserindstillinger. Dette kan dog påvirke funktionaliteten af visse funktioner såsom søgehistorik og Stjernemarkerede Resultater.',
    privacyContent24: '9.2. Da vi ikke indsamler personligt identificerbare oplysninger, kan vi ikke give individuel adgang til eller sletning af søgedata.',
    privacyContent25: '10. Børns privatliv',
    privacyContent26: 'Vores Tjeneste henvender sig ikke til personer under 13 år. Vi indsamler ikke bevidst personligt identificerbare oplysninger fra børn under 13 år. Hvis du er forælder eller værge og er opmærksom på, at dit barn har givet os personlige oplysninger, bedes du kontakte os.',
    privacyContent27: '11. Ændringer i denne privatlivspolitik',
    privacyContent28: 'Vi kan opdatere vores Privatlivspolitik fra tid til anden. Vi vil underrette dig om eventuelle ændringer ved at offentliggøre den nye Privatlivspolitik på denne side og opdatere datoen for "Sidst opdateret" øverst i denne Privatlivspolitik.',
    privacyContent29: '12. Sikkerhed',
    privacyContent30: 'Vi er forpligtet til at sikre, at dine oplysninger er sikre. Vi har implementeret passende fysiske, elektroniske og ledelsesmæssige procedurer for at beskytte og sikre de oplysninger, vi indsamler online.',
    delete: 'Slet',
    availableinpro: 'Ændring af resultater pr. side er KUN tilgængelig i PRO-versionen',
    madebykeyfox: 'Lavet af Keyfox/Jianhu med ❤️'
  }