import { en } from './en';
import { es } from './es';
import { zh } from './zh';
import { fr } from './fr'
import { ru } from './ru'
import { ja } from './ja'
import { da } from './da'
import { ko } from './ko'
// Import other language files as needed

export const translations = {
  en,
  es,
  zh,
  fr,
  ru,
  ja,
  da,
  ko,
};

export const defaultLanguage = 'en';