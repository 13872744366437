import React from 'react';
import { motion } from 'framer-motion';
import logo from '../image/logo.png';

export function Toolbar({
  setShowStarredPopup,
  setShowHistoryPopup,
  setShowReverseSearchPopup,
  setShowGalleryMode,
  setShowSettingsPopup,
  setShowLanguagePopup,
  settings,
  updateSettings,
  currentLanguage,
  t
}) {
  const toolbarButtons = [
    { icon: "★", onClick: () => setShowStarredPopup(true), title: t('viewStarredResults') },
    { icon: "⏱", onClick: () => setShowHistoryPopup(true), title: t('viewSearchHistory') },
    { icon: settings.nsfwFilter ? "🔒" : "🔓", onClick: () => updateSettings('nsfwFilter', !settings.nsfwFilter), title: settings.nsfwFilter ? t('disableNsfwFilter') : t('enableNsfwFilter') },
    { icon: "🖼️", onClick: () => setShowGalleryMode(true), title: t('galleryMode') },
    { icon: "🔄", onClick: () => setShowReverseSearchPopup(true), title: t('reverseImageSearch') },
    { icon: settings.theme === 'dark' ? '☀️' : '🌙', onClick: () => updateSettings('theme', settings.theme === 'dark' ? 'bright' : 'dark'), title: t(settings.theme === 'dark' ? 'switchToBrightTheme' : 'switchToDarkTheme') },
    { icon: "⚙️", onClick: () => setShowSettingsPopup(true), title: t('settings') },
    { icon: `🌐 ${currentLanguage.toUpperCase()}`, onClick: () => setShowLanguagePopup(true), title: t('changeLanguage') },
  ];

  return (
    <div className="toolbar">
      <motion.img
        src={logo}
        alt={t('searchEngineLogo')}
        className="toolbar-logo"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      />
      {toolbarButtons.map((button, index) => (
        <motion.button
          key={index}
          className={`toolbar-button ${button.active ? 'active' : ''}`}
          onClick={button.onClick}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          data-tooltip={button.title}
        >
          {button.icon}
        </motion.button>
      ))}
    </div>
  );
}