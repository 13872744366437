import React from 'react';
import { motion } from 'framer-motion';

const websites = [
  { name: 'All', value: '*' },
  { name: 'Furaffinity', value: 'furaffinity' },
  { name: 'e621', value: 'e621' },
  { name: 'Inkbunny', value: 'inkbunny' },
  { name: 'Flayrah', value: 'flayrah' },
  { name: 'Furbooru', value: 'furbooru' },
  { name: 'Furplanet', value: 'furplanet' },
  { name: 'Furrystation', value: 'furrystation' },
  { name: 'Games', value: 'games' },
  { name: 'Infurnation', value: 'infurnation' },
];

export function WebsiteFilter({ selectedWebsite, onWebsiteChange }) {
  return (
    <div className="website-filter">
      {websites.map((website) => (
        <motion.button
          key={website.value}
          className={`filter-button ${selectedWebsite === website.value ? 'active' : ''}`}
          onClick={() => onWebsiteChange(website.value)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {website.name}
        </motion.button>
      ))}
    </div>
  );
}