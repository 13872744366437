import React from 'react';
import { motion } from 'framer-motion';
import { WebsiteFilter } from './WebsiteFilter';

export function SearchForm({ query, setQuery, onSubmit, selectedWebsite, onWebsiteChange, t }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e, 0, query);
  };

  return (
    <div className="search-form-container">
      <form onSubmit={handleSubmit} className="search-form">
        <div className="search-input-container">
          <motion.input
            whileFocus={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={t('searchPlaceholder')}
            className="search-input"
          />
          <motion.button 
            type="submit" 
            className="search-button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            🔍
          </motion.button>
        </div>
      </form>
      <WebsiteFilter selectedWebsite={selectedWebsite} onWebsiteChange={onWebsiteChange} />
    </div>
  );
}