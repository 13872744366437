// In components/GalleryMode.js

import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';

export function GalleryMode({ results, mainImages, closeGallery, t, goToNextPage, currentPage, totalHits, settings }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [galleryResults, setGalleryResults] = useState([]);

  useEffect(() => {
    const filteredResults = results.filter(hit => mainImages[hit._id]?.fullSize);
    setGalleryResults(filteredResults);
    setCurrentIndex(0);
  }, [results, mainImages]);

  const navigateGallery = useCallback(async (direction) => {
    let newIndex = currentIndex + direction;
    
    if (newIndex < 0 && currentPage > 0) {
      await goToNextPage(-1);
      newIndex = settings.resultsPerPage - 1;
    } else if (newIndex >= galleryResults.length && (currentPage + 1) * settings.resultsPerPage < totalHits) {
      await goToNextPage(1);
      newIndex = 0;
    }

    if (newIndex >= 0 && newIndex < galleryResults.length) {
      setCurrentIndex(newIndex);
    }
  }, [currentIndex, galleryResults, currentPage, goToNextPage, settings.resultsPerPage, totalHits]);

  const currentHit = galleryResults[currentIndex];
  const currentImage = currentHit ? mainImages[currentHit._id]?.fullSize : null;

  if (galleryResults.length === 0) {
    return (
      <div className="gallery-mode-overlay">
        <div className="gallery-mode-content">
          <button className="close-gallery" onClick={closeGallery}>×</button>
          <div className="gallery-no-image">{t('noImagesAvailable')}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="gallery-mode-overlay">
      <div className="gallery-mode-content">
        <button className="close-gallery" onClick={closeGallery}>×</button>
        <button className="gallery-nav prev" onClick={() => navigateGallery(-1)}>&#10094;</button>
        <div className="gallery-image-container">
          {currentImage ? (
            <img src={currentImage} alt="Gallery" className="gallery-image" />
          ) : (
            <div className="gallery-no-image">{t('noImageAvailable')}</div>
          )}
          <div className="gallery-description">
            <h3>{currentHit?._source.title}</h3>
            <p>{currentHit?._source.meta_description}</p>
            <a href={currentHit?._source.url} target="_blank" rel="noopener noreferrer">{t('source')}</a>
          </div>
        </div>
        <button className="gallery-nav next" onClick={() => navigateGallery(1)}>&#10095;</button>
      </div>
    </div>
  );
}