import React from 'react';
import { motion } from 'framer-motion';
import { translations } from '../../translations';

export function LanguagePopup({ currentLanguage, changeLanguage, closePopup, t }) {
  return (
    <motion.div
      className="popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2>{t('changeLanguage')}</h2>
        <motion.button 
          className="close-popup" 
          onClick={closePopup}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          ×
        </motion.button>
        <div className="language-options">
          {Object.keys(translations).map((lang) => (
            <motion.button 
              key={lang} 
              onClick={() => {
                changeLanguage(lang);
                closePopup();
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={currentLanguage === lang ? 'active' : ''}
            >
              {t(`language_${lang}`)}
            </motion.button>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
}