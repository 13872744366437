import { useState, useEffect, useCallback } from 'react';

export function useSearchHistory() {
  const [searchHistory, setSearchHistory] = useState([]);

  useEffect(() => {
    const storedHistory = localStorage.getItem('searchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const addToHistory = useCallback((query) => {
    setSearchHistory(prev => {
      const updatedHistory = [query, ...prev.filter(item => item !== query)].slice(0, 10);
      localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
      return updatedHistory;
    });
  }, []);

  const clearHistory = useCallback(() => {
    setSearchHistory([]);
    localStorage.removeItem('searchHistory');
  }, []);

  return {
    searchHistory,
    addToHistory,
    clearHistory
  };
}