import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ToSPopup } from './Popups/ToSPopup';
import { PrivacyPopup } from './Popups/PrivacyPopup';

export function Footer({ t }) {
  const [showToSPopup, setShowToSPopup] = useState(false);
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 SearchFur. {t('allRightsReserved')}</p>
        <p>{t('madebykeyfox')}</p>
        <motion.button
          className="footer-link"
          onClick={() => setShowToSPopup(true)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {t('termsOfService')}
        </motion.button>
        <motion.button
          className="footer-link"
          onClick={() => setShowPrivacyPopup(true)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {t('privacyPolicy')}
        </motion.button>
      </div>
      {showToSPopup && <ToSPopup closePopup={() => setShowToSPopup(false)} t={t} />}
      {showPrivacyPopup && <PrivacyPopup closePopup={() => setShowPrivacyPopup(false)} t={t} />}
    </footer>
  );
}