import React from 'react';
import { motion } from 'framer-motion';

export function ToSPopup({ closePopup, t }) {
  return (
    <motion.div
      className="popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2>{t('termsOfService')}</h2>
        <motion.button 
          className="close-popup" 
          onClick={closePopup}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          ×
        </motion.button>
        <div className="tos-content">
          {/* Add your Terms of Service content here */}
          <p>{t('tos_pp_date')}</p>
          <p>{t('tosContent')}</p>
          <p>{t('tosContent1')}</p>
          <p>{t('tosContent2')}</p>
          <p>{t('tosContent3')}</p>
          <p>{t('tosContent4')}</p>
          <p>{t('tosContent5')}</p>
          <p>{t('tosContent6')}</p>
          <p>{t('tosContent7')}</p>
          <p>{t('tosContent8')}</p>
          <p>{t('tosContent9')}</p>
          <p>{t('tosContent10')}</p>
          <p>{t('tosContent11')}</p>
          <p>{t('tosContent12')}</p>
          <p>{t('tosContent13')}</p>
          <p>{t('tosContent14')}</p>
          <p>{t('tosContent15')}</p>
          <p>{t('tosContent16')}</p>
          <p>{t('tosContent17')}</p>
          <p>{t('tosContent18')}</p>
          <p>{t('tosContent19')}</p>
          <p>{t('tosContent20')}</p>
          <p>{t('tosContent21')}</p>
          <p>{t('tosContent22')}</p>
          <p>{t('tosContent23')}</p>
          <p>{t('tosContent24')}</p>
        </div>
      </motion.div>
    </motion.div>
  );
}