export const fr = {
    searchPlaceholder: "À la recherche de quelque chose de poilu...",
    resultsFound: "Environ {0} résultats trouvés",
    noResults: "Oups, pas de résultats dans ce monde poilu.",
    loading: "Chargement...",
    previousButton: "Précédent",
    nextButton: "Suivant",
    page: "Page {0}",
    starredResults: "Résultats favoris",
    noStarredResults: "Pas encore de résultats favoris.",
    searchHistory: "Historique de recherche",
    noSearchHistory: "Pas encore d'historique de recherche.",
    clearHistory: "Effacer l'historique",
    deleteFolder: "Supprimer le dossier",
    deleteFolderConfirm: 'Êtes-vous sûr de vouloir supprimer le dossier "{0}" et tout son contenu ?',
    yesDelete: "Oui, supprimer",
    cancel: "Annuler",
    renameFolder: "Renommer le dossier",
    rename: "Renommer",
    newFolderName: "Nouveau nom de dossier",
    reverseImageSearch: "Recherche d'image inversée",
    uploadImage: "Télécharger une image",
    changeImage: "Changer l'image",
    search: "Rechercher",
    searchingVisualRealm: "Recherche en cours...",
    searchResults: "Résultats de recherche",
    score: "Score : {0}%",
    viewOriginal: "Voir l'original",
    settings: "Paramètres",
    displaySettings: "Paramètres d'affichage",
    showImages: "Afficher les images",
    showImagesDesc: "Afficher les vignettes des images dans les résultats de recherche.",
    nsfwEffect: "Effet NSFW",
    nsfwEffectDesc: "Appliquer un effet visuel au contenu potentiellement sensible.",
    colorTheme: "Thème de couleur",
    colorThemeDesc: "Choisissez entre les thèmes sombre et clair.",
    searchSettings: "Paramètres de recherche",
    resultsPerPage: "Résultats par page :",
    resultsPerPageDesc: "Définir le nombre de résultats de recherche affichés sur chaque page.",
    nsfwFilter: "Filtre NSFW",
    nsfwFilterDesc: "Filtrer le contenu potentiellement sensible des résultats de recherche.",
    saveSettings: "Enregistrer les paramètres",
    disableNsfwFilter: 'Désactiver le filtre NSFW',
    enableNsfwFilter: 'Activer le filtre NSFW',
    previous: 'Précédent',
    next: 'Suivant',
    noDescription: " ",
    searching: "Recherche dans le multivers pelucheux !",
    viewStarredResults: "Voir les résultats favoris",
    folders: "Dossiers",
    unorganizedStars: "Favoris non organisés",
    noFolder: "Aucun dossier",
    createFolder: "Créer un dossier",
    viewSearchHistory: "Voir l'historique de recherche",
    hideImages: "Masquer les images",
    galleryMode: "Mode galerie",
    hideNsfwEffect: "Masquer l'effet NSFW",
    switchToBrightTheme: "Passer au thème clair",
    switchToDarkTheme: "Passer au thème sombre",
    searchEngineSettings: "Paramètres",
    changeLanguage: "Langue",
    theme: "Thème",
    dark: "Sombre",
    bright: "Clair",
    poweredByFluffy: "Propulsé par Fluffy.xyz créé par NoppesTheFolf",
    exportData: "Exporter les données",
    importData: "Importer les données",
    importWarning: "Attention : L'importation de données écrasera vos résultats favoris et dossiers actuels. Voulez-vous continuer ?",
    importSuccess: "Données importées avec succès !",
    importError: "Erreur lors de l'importation des données. Veuillez vérifier le fichier et réessayer.",
    noImagesAvailable: "Aucune image disponible",
    allRightsReserved: 'Tous droits réservés.',
    termsOfService: 'Conditions d"utilisation',
    privacyPolicy: 'Politique de confidentialité',
    tos_pp_date: 'Dernière mise à jour : 8 août 2024',
    tosContent: '1. Acceptation des conditions',
    tosContent1: 'En accédant ou en utilisant SearchFur (le "Service"), vous acceptez d"être lié par ces Conditions d"utilisation ("Conditions"). Si vous n"êtes pas d"accord avec une partie des conditions, vous ne pouvez pas accéder au Service.',
    tosContent2: '2. Description du service',
    tosContent3: 'SearchFur est un moteur de recherche qui permet aux utilisateurs de rechercher des informations sur Internet. Le Service peut stocker des cookies sur les appareils des utilisateurs pour maintenir l"historique de recherche et les Résultats favoris.',
    tosContent4: '3. Responsabilités de lutilisateur',
    tosContent5: '3.1. Vous acceptez d"utiliser le Service uniquement à des fins légales et conformément à ces Conditions.',
    tosContent6: '3.2. Vous êtes responsable du maintien de la confidentialité de toute information personnelle que vous fournissez lors de l"utilisation du Service.',
    tosContent7: '4. Propriété intellectuelle',
    tosContent8: '4.1. Le Service et son contenu original, ses fonctionnalités et sa fonctionnalité sont et resteront la propriété exclusive de SearchFur et de ses concédants.',
    tosContent9: '4.2. Les utilisateurs conservent tous les droits sur le contenu de leurs recherches.',
    tosContent10: '5. Publicité',
    tosContent11: '5.1. SearchFur peut afficher des publicités dans le cadre du Service. Ces publicités aident à soutenir et à maintenir les aspects gratuits de notre Service.',
    tosContent12: '5.2. Nous n"utilisons pas de données personnelles pour cibler les publicités. Toutes les publicités sont contextuelles basées sur les requêtes de recherche ou des catégories générales.',
    tosContent13: '6. Fonctionnalités Pro',
    tosContent14: '6.1. SearchFur peut offrir des fonctionnalités premium à l"avenir dans le cadre d"un service d"abonnement payant ("Fonctionnalités Pro").',
    tosContent15: '6.2. Les conditions pour les Fonctionnalités Pro seront fournies séparément si et quand ces fonctionnalités deviendront disponibles.',
    tosContent16: '6.3. Les fonctionnalités gratuites resteront disponibles pour tous les utilisateurs.',
    tosContent17: '7. Recherche d"image inversée',
    tosContent18: '7.1. SearchFur utilise Fluffle.xyz pour la fonctionnalité de recherche d"image inversée. En utilisant cette fonctionnalité, vous acceptez les conditions d"utilisation et la politique de confidentialité de Fluffle.',
    tosContent19: '7.2. Lorsque vous utilisez la fonction de recherche d"image inversée, votre image sera temporairement stockée sur les serveurs de Fluffle pour traitement. L"image est supprimée immédiatement après le traitement, sauf lorsque vous créez un lien permanent vers le résultat de recherche.',
    tosContent20: '7.3. SearchFur n"est pas responsable des pratiques de confidentialité ou du contenu de Fluffle.xyz. Nous vous encourageons à consulter la politique de confidentialité de Fluffle pour plus d"informations.',
    tosContent21: '8. Limitation de responsabilité',
    tosContent22: '8.1. SearchFur ne sera pas responsable des dommages indirects, accessoires, spéciaux, consécutifs ou punitifs résultant de votre accès ou utilisation, ou de l"impossibilité d"accéder ou d"utiliser, le Service.',
    tosContent23: '9. Modifications des conditions',
    tosContent24: '9.1. Nous nous réservons le droit de modifier ou de remplacer ces Conditions à tout moment. Si une révision est importante, nous fournirons un préavis d"au moins 30 jours avant que les nouvelles conditions ne prennent effet.',
    privacyContent: '1. Introduction',
    privacyContent1: 'Cette Politique de confidentialité décrit comment SearchFur ("nous", "notre" ou "nos") collecte, utilise et partage les informations vous concernant lorsque vous utilisez notre service de moteur de recherche. Nous nous engageons à protéger votre vie privée et à vous assurer une expérience positive sur notre site web.',
    privacyContent2: '2. Informations que nous collectons',
    privacyContent3: '2.1. Requêtes de recherche : Nous collectons et stockons les requêtes de recherche que vous entrez dans SearchFur. Ces informations sont collectées de manière anonyme et ne peuvent pas être liées à des utilisateurs individuels.',
    privacyContent4: '2.2. Cookies : Nous utilisons des cookies pour stocker votre historique de recherche et vos Résultats favoris sur votre appareil. Ces informations restent sur votre appareil et ne sont pas collectées ni accessibles par SearchFur.',
    privacyContent5: '3. Comment nous utilisons les informations',
    privacyContent6: '3.1. Nous utilisons les requêtes de recherche anonymisées pour améliorer notre service de moteur de recherche et fournir des résultats de recherche plus pertinents.',
    privacyContent7: '3.2. Nous n"utilisons pas les informations stockées dans les cookies (historique de recherche et Résultats favoris) car ces informations restent uniquement sur votre appareil.',
    privacyContent8: '4. Publicité',
    privacyContent9: '4.1. Nous pouvons afficher des publicités dans le cadre de notre Service. Ces publicités sont basées sur le contexte de vos requêtes de recherche ou des catégories générales.',
    privacyContent10: '4.2. Nous ne collectons ni n"utilisons d"informations personnelles pour le ciblage publicitaire. Toutes les publicités sont diffusées en fonction du contenu de vos recherches ou de sujets généraux, et non sur des profils d"utilisateurs ou des données personnelles.',
    privacyContent11: '5. Recherche d"image inversée',
    privacyContent12: '5.1. Pour la fonctionnalité de recherche d"image inversée, SearchFur utilise Fluffle.xyz, un service tiers.',
    privacyContent13: '5.2. Lorsque vous utilisez la fonction de recherche d"image inversée : 1. Votre image est temporairement stockée sur les serveurs de Fluffle pour traitement. 2. L"image est supprimée immédiatement après le traitement, sauf lorsque vous créez un lien permanent vers le résultat de recherche. 3. Fluffle crée une empreinte de votre image pour comparaison mais ne stocke pas cette empreinte. 4. Fluffle enregistre certaines informations non personnelles sur votre requête à des fins de performance et de sécurité.',
    privacyContent14: '5.3. Fluffle utilise la plateforme d"analyse web de Cloudflare, qui ne collecte pas d"informations personnelles, ne suit pas le comportement de navigation et n"utilise pas de cookies.',
    privacyContent15: '5.4. Pour des informations plus détaillées, veuillez vous référer à la politique de confidentialité de Fluffle.',
    privacyContent16: '6. Fonctionnalités Pro',
    privacyContent17: '6.1. Si nous introduisons des Fonctionnalités Pro à l"avenir, nous pourrions collecter des informations supplémentaires nécessaires pour fournir ces services, telles que des informations de compte et des détails de paiement. 6.2. Toute collecte de données supplémentaire liée aux Fonctionnalités Pro sera clairement communiquée avant la mise en œuvre, et les utilisateurs auront le choix d"opter pour ces fonctionnalités.',
    privacyContent18: '7. Partage et divulgation d"informations',
    privacyContent19: '7.1. Nous ne vendons, n"échangeons ni ne transférons autrement vos informations personnellement identifiables à des tiers. 7.2. Nous pouvons divulguer des informations lorsque la loi l"exige ou pour protéger nos droits, notre vie privée, notre sécurité, notre propriété ou celle d"autrui.',
    privacyContent20: '8. Conservation des données',
    privacyContent21: 'Nous conservons les requêtes de recherche anonymisées aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cette Politique de confidentialité, sauf si une période de conservation plus longue est requise ou autorisée par la loi.',
    privacyContent22: '9. Vos choix',
    privacyContent23: '9.1. Vous pouvez choisir de désactiver les cookies via les paramètres de votre navigateur. Cependant, cela peut affecter la fonctionnalité de certaines fonctionnalités comme l"historique de recherche et les Résultats favoris.',
    privacyContent24: '9.2. Comme nous ne collectons pas d"informations personnellement identifiables, nous ne pouvons pas fournir d"accès individuel ou de suppression des données de recherche.',
    privacyContent25: '10. Confidentialité des enfants',
    privacyContent26: 'Notre Service ne s"adresse pas aux personnes de moins de 13 ans. Nous ne collectons pas sciemment d"informations personnellement identifiables auprès d"enfants de moins de 13 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des informations personnelles, veuillez nous contacter.',
    privacyContent27: '11. Modifications de cette politique de confidentialité',
    privacyContent28: 'Nous pouvons mettre à jour notre Politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle Politique de confidentialité sur cette page et en mettant à jour la date de "Dernière mise à jour" en haut de cette Politique de confidentialité.',
    privacyContent29: '12. Sécurité',
    privacyContent30: 'Nous nous engageons à assurer la sécurité de vos informations. Nous avons mis en place des procédures physiques, électroniques et de gestion appropriées pour sauvegarder et sécuriser les informations que nous collectons en ligne.',
    delete: 'Supprimer',
    availableinpro: 'La modification du nombre de résultats par page est UNIQUEMENT disponible en version PRO',
    madebykeyfox: 'Créé par Keyfox/Jianhu avec ❤️'
}