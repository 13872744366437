export const ko = {
  searchPlaceholder: "털 복슬복슬한 무언가를 찾는 중...",
  resultsFound: "약 {0}개의 결과를 찾았습니다",
  noResults: "앗, 이 털 복슬복슬한 세상에서 결과를 찾지 못했어요.",
  loading: "로딩 중...",
  previousButton: "이전",
  nextButton: "다음",
  page: "페이지 {0}",
  starredResults: "즐겨찾기 결과",
  noStarredResults: "아직 즐겨찾기한 결과가 없습니다.",
  searchHistory: "검색 기록",
  noSearchHistory: "아직 검색 기록이 없습니다.",
  clearHistory: "기록 지우기",
  deleteFolder: "폴더 삭제",
  deleteFolderConfirm: '"{0}" 폴더와 모든 내용을 삭제하시겠습니까?',
  yesDelete: "네, 삭제합니다",
  cancel: "취소",
  renameFolder: "폴더 이름 바꾸기",
  rename: "이름 바꾸기",
  newFolderName: "새 폴더 이름",
  reverseImageSearch: "이미지 검색",
  uploadImage: "이미지 업로드",
  changeImage: "이미지 변경",
  search: "검색",
  searchingVisualRealm: "검색 중...",
  searchResults: "검색 결과",
  score: "유사도: {0}%",
  viewOriginal: "원본 보기",
  settings: "설정",
  displaySettings: "디스플레이 설정",
  showImages: "이미지 표시",
  showImagesDesc: "검색 결과에 썸네일 이미지를 표시합니다.",
  nsfwEffect: "NSFW 효과",
  nsfwEffectDesc: "민감한 콘텐츠에 시각적 효과를 적용합니다.",
  colorTheme: "색상 테마",
  colorThemeDesc: "어두운 테마와 밝은 테마 중 선택하세요.",
  searchSettings: "검색 설정",
  resultsPerPage: "페이지당 결과 수:",
  resultsPerPageDesc: "각 페이지에 표시될 검색 결과의 수를 설정합니다.",
  nsfwFilter: "NSFW 필터",
  nsfwFilterDesc: "검색 결과에서 민감한 콘텐츠를 필터링합니다.",
  saveSettings: "설정 저장",
  disableNsfwFilter: 'NSFW 필터 비활성화',
  enableNsfwFilter: 'NSFW 필터 활성화',
  previous: '이전',
  next: '다음',
  noDescription: " ",
  searching: "털 복슬복슬한 다중 우주를 검색 중!",
  viewStarredResults: "즐겨찾기 결과 보기",
  folders: "폴더",
  unorganizedStars: "정리되지 않은 즐겨찾기",
  noFolder: "폴더 없음",
  createFolder: "폴더 만들기",
  viewSearchHistory: "검색 기록 보기",
  hideImages: "이미지 숨기기",
  galleryMode: "갤러리 모드",
  hideNsfwEffect: "NSFW 효과 숨기기",
  switchToBrightTheme: "밝은 테마로 전환",
  switchToDarkTheme: "어두운 테마로 전환",
  searchEngineSettings: "설정",
  changeLanguage: "언어",
  theme: "테마",
  dark: "어두운",
  bright: "밝은",
  poweredByFluffy: "NoppesTheFolf가 만든 Fluffy.xyz 제공",
  exportData: "데이터 내보내기",
  importData: "데이터 가져오기",
  importWarning: "경고: 데이터를 가져오면 현재 즐겨찾기 결과와 폴더를 덮어씁니다. 계속하시겠습니까?",
  importSuccess: "데이터를 성공적으로 가져왔습니다!",
  importError: "데이터 가져오기 오류. 파일을 확인하고 다시 시도해 주세요.",
  noImagesAvailable: "사용 가능한 이미지 없음",
  allRightsReserved: '모든 권리 보유.',
  termsOfService: '서비스 약관',
  privacyPolicy: '개인정보 처리방침',
  tos_pp_date: '최종 업데이트: 2024년 8월 8일',
  tosContent: '1. 약관 동의',
  tosContent1: 'SearchFur("서비스")에 접속하거나 이용함으로써 귀하는 본 서비스 약관("약관")에 동의하게 됩니다. 약관의 일부에 동의하지 않는 경우 서비스를 이용할 수 없습니다.',
  tosContent2: '2. 서비스 설명',
  tosContent3: 'SearchFur는 사용자가 인터넷에서 정보를 검색할 수 있게 해주는 검색 엔진입니다. 이 서비스는 검색 기록과 즐겨찾기 결과를 유지하기 위해 사용자 기기에 쿠키를 저장할 수 있습니다.',
  tosContent4: '3. 사용자 책임',
  tosContent5: '3.1. 귀하는 본 서비스를 합법적인 목적으로만 사용하며 이 약관을 준수할 것에 동의합니다.',
  tosContent6: '3.2. 귀하는 서비스 이용 중 제공한 개인 정보의 기밀성을 유지할 책임이 있습니다.',
  tosContent7: '4. 지적 재산권',
  tosContent8: '4.1. 본 서비스와 그 원본 콘텐츠, 기능, 기능성은 현재와 미래에도 SearchFur와 그 라이선스 제공자의 독점 재산입니다.',
  tosContent9: '4.2. 사용자는 자신의 검색 내용에 대한 모든 권리를 보유합니다.',
  tosContent10: '5. 광고',
  tosContent11: '5.1. SearchFur는 서비스의 일부로 광고를 표시할 수 있습니다. 이 광고는 우리 서비스의 무료 부분을 지원하고 유지하는 데 도움이 됩니다.',
  tosContent12: '5.2. 우리는 개인 데이터를 사용하여 광고를 타겟팅하지 않습니다. 모든 광고는 검색 쿼리나 일반 카테고리를 기반으로 한 문맥 광고입니다.',
  tosContent13: '6. 프로 기능',
  tosContent14: '6.1. SearchFur는 향후 유료 구독 서비스의 일부로 프리미엄 기능("프로 기능")을 제공할 수 있습니다.',
  tosContent15: '6.2. 프로 기능에 대한 약관은 해당 기능이 사용 가능해질 때 별도로 제공됩니다.',
  tosContent16: '6.3. 무료 기능은 모든 사용자에게 계속 제공됩니다.',
  tosContent17: '7. 이미지 검색',
  tosContent18: '7.1. SearchFur는 이미지 검색 기능을 위해 Fluffle.xyz를 사용합니다. 이 기능을 사용함으로써 귀하는 Fluffle의 서비스 약관 및 개인정보 처리방침에 동의하게 됩니다.',
  tosContent19: '7.2. 이미지 검색 기능을 사용할 때 귀하의 이미지는 처리를 위해 Fluffle의 서버에 일시적으로 저장됩니다. 검색 결과에 대한 영구 링크를 생성하지 않는 한, 이미지는 처리 후 즉시 삭제됩니다.',
  tosContent20: '7.3. SearchFur는 Fluffle.xyz의 개인정보 처리 방식이나 콘텐츠에 대해 책임지지 않습니다. 자세한 정보는 Fluffle의 개인정보 처리방침을 검토하시기 바랍니다.',
  tosContent21: '8. 책임의 제한',
  tosContent22: '8.1. SearchFur는 서비스에 대한 접근, 사용 또는 접근 불가, 사용 불가로 인해 발생하는 간접적, 우발적, 특별, 결과적 또는 징벌적 손해에 대해 책임지지 않습니다.',
  tosContent23: '9. 약관 변경',
  tosContent24: '9.1. 우리는 언제든지 이 약관을 수정하거나 대체할 권리를 보유합니다. 수정 사항이 중요한 경우, 새로운 약관이 발효되기 최소 30일 전에 통지를 제공할 것입니다.',
  privacyContent: '1. 소개',
  privacyContent1: '이 개인정보 처리방침은 SearchFur("우리", "우리의" 또는 "저희")가 귀하가 우리의 검색 엔진 서비스를 사용할 때 귀하에 대한 정보를 어떻게 수집, 사용 및 공유하는지 설명합니다. 우리는 귀하의 개인정보를 보호하고 웹사이트에서 긍정적인 경험을 제공하기 위해 최선을 다하고 있습니다.',
  privacyContent2: '2. 우리가 수집하는 정보',
  privacyContent3: '2.1. 검색 쿼리: 우리는 귀하가 SearchFur에 입력하는 검색 쿼리를 수집하고 저장합니다. 이 정보는 익명으로 수집되며 개별 사용자와 연결될 수 없습니다.',
  privacyContent4: '2.2. 쿠키: 우리는 귀하의 검색 기록과 즐겨찾기 결과를 귀하의 기기에 저장하기 위해 쿠키를 사용합니다. 이 정보는 귀하의 기기에 남아 있으며 SearchFur에 의해 수집되거나 접근되지 않습니다.',
  privacyContent5: '3. 정보 사용 방법',
  privacyContent6: '3.1. 우리는 익명화된 검색 쿼리를 사용하여 검색 엔진 서비스를 개선하고 더 관련성 있는 검색 결과를 제공합니다.',
  privacyContent7: '3.2. 우리는 쿠키에 저장된 정보(검색 기록 및 즐겨찾기 결과)를 사용하지 않습니다. 이 정보는 귀하의 기기에만 남아 있습니다.',
  privacyContent8: '4. 광고',
  privacyContent9: '4.1. 우리는 서비스의 일부로 광고를 표시할 수 있습니다. 이 광고는 귀하의 검색 쿼리 맥락이나 일반 카테고리를 기반으로 합니다.',
  privacyContent10: '4.2. 우리는 광고 타겟팅을 위해 개인 정보를 수집하거나 사용하지 않습니다. 모든 광고는 사용자 프로필이나 개인 데이터가 아닌 귀하의 검색 내용이나 일반 주제를 기반으로 제공됩니다.',
  privacyContent11: '5. 이미지 검색',
  privacyContent12: '5.1. SearchFur는 이미지 검색 기능을 위해 제3자 서비스인 Fluffle.xyz를 사용합니다.',
  privacyContent13: '5.2. 이미지 검색 기능을 사용할 때: 1. 귀하의 이미지는 처리를 위해 Fluffle의 서버에 일시적으로 저장됩니다. 2. 검색 결과에 대한 영구 링크를 생성하지 않는 한, 이미지는 처리 후 즉시 삭제됩니다. 3. Fluffle은 비교를 위해 귀하의 이미지의 지문을 생성하지만 이 지문을 저장하지 않습니다. 4. Fluffle은 성능 및 보안 목적으로 귀하의 요청에 대한 특정 비개인 정보를 기록합니다.',
  privacyContent14: '5.3. Fluffle은 Cloudflare의 웹 분석 플랫폼을 사용합니다. 이 플랫폼은 개인 정보를 수집하지 않고, 브라우징 행동을 추적하지 않으며, 쿠키를 사용하지 않습니다.',
  privacyContent15: '5.4. 더 자세한 정보는 Fluffle의 개인정보 처리방침을 참조하시기 바랍니다.',
  privacyContent16: '6. 프로 기능',
  privacyContent17: '6.1. 향후 프로 기능을 도입할 경우, 우리는 이러한 서비스 제공에 필요한 추가 정보(예: 계정 정보 및 결제 세부 정보)를 수집할 수 있습니다. 6.2. 프로 기능과 관련된 추가 데이터 수집은 구현 전에 명확히 전달될 것이며, 사용자는 이러한 기능에 참여할 수 있는 선택권을 갖게 됩니다.',
  privacyContent18: '7. 정보 공유 및 공개',
  privacyContent19: '7.1. 우리는 귀하의 개인 식별 정보를 외부 당사자에게 판매, 교환 또는 기타 방식으로 전송하지 않습니다. 7.2. 우리는 법률에 의해 요구되거나 우리 또는 타인의 권리, 개인정보, 안전, 재산을 보호하기 위해 필요한 경우 정보를 공개할 수 있습니다.',
  privacyContent20: '8. 데이터 보유',
  privacyContent21: '우리는 이 개인정보 처리방침에 명시된 목적을 달성하는 데 필요한 기간 동안 익명화된 검색 쿼리를 보유합니다. 단, 법률에 의해 더 긴 보유 기간이 요구되거나 허용되는 경우는 예외입니다.',
  privacyContent22: '9. 귀하의 선택',
  privacyContent23: '9.1. 귀하는 브라우저 설정을 통해 쿠키를 비활성화할 수 있습니다. 그러나 이는 검색 기록 및 즐겨찾기 결과와 같은 일부 기능의 기능성에 영향을 줄 수 있습니다.',
  privacyContent24: '9.2. 우리는 개인 식별 정보를 수집하지 않기 때문에 개별 검색 데이터에 대한 접근이나 삭제를 제공할 수 없습니다.',
  privacyContent25: '10. 어린이의 개인정보',
  privacyContent26: '우리의 서비스는 13세 미만의 어린이를 대상으로 하지 않습니다. 우리는 13세 미만 어린이의 개인 식별 정보를 고의로 수집하지 않습니다. 귀하가 부모 또는 보호자이고 귀하의 자녀가 우리에게 개인 정보를 제공했다는 사실을 알게 된 경우, 우리에게 연락해 주시기 바랍니다.',
  privacyContent27: '11. 본 개인정보 처리방침의 변경',
  privacyContent28: '우리는 때때로 개인정보 처리방침을 업데이트할 수 있습니다. 우리는 이 페이지에 새로운 개인정보 처리방침을 게시하고 이 개인정보 처리방침 상단의 "최종 업데이트" 날짜를 업데이트하여 변경 사항을 알려드릴 것입니다.',
  privacyContent29: '12. 보안',
  privacyContent30: '우리는 귀하의 정보 보안을 보장하기 위해 최선을 다하고 있습니다. 우리는 온라인에서 수집하는 정보를 보호하고 보안을 유지하기 위해 적절한 물리적, 전자적, 관리적 절차를 구현했습니다.',
  delete: '삭제',
  availableinpro: '페이지당 결과 수 변경은 PRO 버전에서만 가능합니다',
  madebykeyfox: 'Keyfox/Jianhu가 ❤️으로 만들었습니다'
}