import React from 'react';
import { motion } from 'framer-motion';

const Ad = ({ adData }) => {
  if (!adData) return null;

  const renderMedia = () => {
    switch (adData.mediaType) {
      case 'image':
      case 'gif':
        return (
          <div className="ad-media-container">
            <img src={adData.mediaUrl} alt={adData.title} className="ad-media" />
          </div>
        );
      case 'video':
        return (
          <div className="ad-media-container">
            <video className="ad-media" loop muted playsInline>
              <source src={adData.mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <motion.div 
      className="ad-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <p className="ad-label">Sponsored</p>
      <h3 className="ad-title">{adData.title}</h3>
      <p className="ad-description">{adData.description}</p>
      <a href={adData.link} target="_blank" rel="noopener noreferrer" className="ad-link">
        {renderMedia()}
      </a>
    </motion.div>
  );
};

export default Ad;