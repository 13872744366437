import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import { SearchForm } from './components/SearchForm';
import { SearchResults } from './components/SearchResults';
import { Toolbar } from './components/Toolbar';
import { StarredPopup } from './components/Popups/StarredPopup';
import { HistoryPopup } from './components/Popups/HistoryPopup';
import { DeleteFolderPopup } from './components/Popups/DeleteFolderPopup';
import { RenameFolderPopup } from './components/Popups/RenameFolderPopup';
import { ReverseSearchPopup } from './components/Popups/ReverseSearchPopup';
import { SettingsPopup } from './components/Popups/SettingsPopup';
import { LanguagePopup } from './components/Popups/LanguagePopup';
import { GalleryMode } from './components/GalleryMode';
import { ImagePopup } from './components/ImagePopup';
import { Footer } from './components/Footer';
import { useSearch } from './hooks/useSearch';
import { useSearchHistory } from './hooks/useSearchHistory';
import { SettingsProvider, useSettings } from './contexts/SettingsContext';
import { LanguageProvider, useLanguage } from './contexts/LanguageContext';
import { fetchMainImage, fetchAds } from './utils/helpers';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { query, setQuery, results, isLoading, error, handleSearch, currentPage, totalHits, goToNextPage, goToPreviousPage, selectedWebsite, changeWebsite } = useSearch();
  const { searchHistory, addToHistory, clearHistory } = useSearchHistory();
  const { settings, updateSettings } = useSettings();
  const { t, currentLanguage, changeLanguage } = useLanguage();

  const [starredData, setStarredData] = useState({
    results: [],
    folders: {}
  });

  const [showStarredPopup, setShowStarredPopup] = useState(false);
  const [showHistoryPopup, setShowHistoryPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [showReverseSearchPopup, setShowReverseSearchPopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showLanguagePopup, setShowLanguagePopup] = useState(false);
  const [showGalleryMode, setShowGalleryMode] = useState(false);
  const [folderToModify, setFolderToModify] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const [mainImages, setMainImages] = useState({});
  const [fullSizeImage, setFullSizeImage] = useState(null);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(true);
  const [ads, setAds] = useState([]);

  const updateStarredResults = useCallback((newResults) => {
    setStarredData(prev => ({ ...prev, results: newResults }));
    localStorage.setItem('starredResults', JSON.stringify(newResults));
  }, []);

  const updateStarredFolders = useCallback((newFolders) => {
    setStarredData(prev => ({ ...prev, folders: newFolders }));
    localStorage.setItem('starredFolders', JSON.stringify(newFolders));
  }, []);

  const updateStarredData = useCallback((newResults, newFolders) => {
    setStarredData({ results: newResults, folders: newFolders });
    localStorage.setItem('starredResults', JSON.stringify(newResults));
    localStorage.setItem('starredFolders', JSON.stringify(newFolders));
  }, []);

  const isAnyPopupOpen = useCallback(() => {
    return showStarredPopup || showHistoryPopup || showDeletePopup || 
           showRenamePopup || showReverseSearchPopup || showSettingsPopup || 
           showLanguagePopup || showGalleryMode || fullSizeImage;
  }, [showStarredPopup, showHistoryPopup, showDeletePopup, showRenamePopup,
      showReverseSearchPopup, showSettingsPopup, showLanguagePopup, showGalleryMode,
      fullSizeImage]);

  useEffect(() => {
    setIsSearchBarVisible(!isAnyPopupOpen());
  }, [isAnyPopupOpen]);

  useEffect(() => {
    const storedResults = localStorage.getItem('starredResults');
    const storedFolders = localStorage.getItem('starredFolders');
    if (storedResults) {
      setStarredData(prev => ({ ...prev, results: JSON.parse(storedResults) }));
    }
    if (storedFolders) {
      setStarredData(prev => ({ ...prev, folders: JSON.parse(storedFolders) }));
    }

    const params = new URLSearchParams(location.search);
    const urlQuery = params.get('q');
    const urlSite = params.get('site');
    const urlPage = params.get('page');
    if (urlQuery) {
      setQuery(urlQuery);
      handleSearch(null, urlPage ? parseInt(urlPage, 10) : 0, urlQuery, urlSite || '*');
    }

    // Fetch ads when the component mounts
    fetchAds().then(fetchedAds => setAds(fetchedAds));
  }, []);

  useEffect(() => {
    if (settings.theme === 'bright') {
      document.body.classList.add('bright-theme');
    } else {
      document.body.classList.remove('bright-theme');
    }
  }, [settings.theme]);

  useEffect(() => {
    if (results.length > 0) {
      results.forEach(hit => {
        fetchMainImage(hit._source.url).then(imageData => {
          if (imageData) {
            setMainImages(prev => ({ ...prev, [hit._id]: imageData }));
          }
        });
      });
    }
  }, [results]);

  const toggleStar = useCallback((hit) => {
    setStarredData(prev => {
      const isStarred = prev.results.some(r => r._id === hit._id);
      let newResults;
      if (isStarred) {
        newResults = prev.results.filter(r => r._id !== hit._id);
      } else {
        newResults = [...prev.results, hit];
      }
      
      const newFolders = { ...prev.folders };
      if (isStarred) {
        Object.keys(newFolders).forEach(folderName => {
          newFolders[folderName] = newFolders[folderName].filter(id => id !== hit._id);
        });
      }

      localStorage.setItem('starredResults', JSON.stringify(newResults));
      localStorage.setItem('starredFolders', JSON.stringify(newFolders));

      return { results: newResults, folders: newFolders };
    });
  }, []);

  const createFolder = useCallback((folderName) => {
    if (folderName && !starredData.folders[folderName]) {
      updateStarredFolders({ ...starredData.folders, [folderName]: [] });
    }
  }, [starredData.folders, updateStarredFolders]);

  const deleteFolder = useCallback((folderName) => {
    const { [folderName]: deletedFolder, ...updatedFolders } = starredData.folders;
    updateStarredFolders(updatedFolders);
    
    const updatedResults = starredData.results.filter(item => !deletedFolder.includes(item._id));
    updateStarredResults(updatedResults);
  }, [starredData, updateStarredFolders, updateStarredResults]);

  const renameFolder = useCallback((oldName, newName) => {
    if (newName && !starredData.folders[newName]) {
      const updatedFolders = { ...starredData.folders, [newName]: starredData.folders[oldName] };
      delete updatedFolders[oldName];
      updateStarredFolders(updatedFolders);
    }
  }, [starredData.folders, updateStarredFolders]);

  const moveToFolder = useCallback((hitId, folderName) => {
    const updatedFolders = { ...starredData.folders };
    Object.keys(updatedFolders).forEach(folder => {
      updatedFolders[folder] = updatedFolders[folder].filter(id => id !== hitId);
    });
    if (folderName) {
      updatedFolders[folderName] = [...(updatedFolders[folderName] || []), hitId];
    }
    updateStarredFolders(updatedFolders);
  }, [starredData.folders, updateStarredFolders]);

  const openFullSizeImage = useCallback((imageUrl) => {
    setFullSizeImage(imageUrl);
  }, []);

  const handleWebsiteChange = useCallback((website) => {
    changeWebsite(website);
  }, [changeWebsite]);

  return (
    <div className={`App ${settings.theme === 'bright' ? 'bright-theme' : ''}`}>
      <Toolbar
        setShowStarredPopup={setShowStarredPopup}
        setShowHistoryPopup={setShowHistoryPopup}
        setShowReverseSearchPopup={setShowReverseSearchPopup}
        setShowGalleryMode={setShowGalleryMode}
        setShowSettingsPopup={setShowSettingsPopup}
        setShowLanguagePopup={setShowLanguagePopup}
        settings={settings}
        updateSettings={updateSettings}
        currentLanguage={currentLanguage}
        t={t}
      />
      <div className="main-content">
        {isSearchBarVisible && (
          <SearchForm 
            query={query} 
            setQuery={setQuery} 
            onSubmit={(e) => handleSearch(e, 0, query, selectedWebsite)}
            selectedWebsite={selectedWebsite}
            onWebsiteChange={handleWebsiteChange}
            t={t} 
          />
        )}
        <SearchResults
          results={results}
          isLoading={isLoading}
          error={error}
          currentPage={currentPage}
          totalHits={totalHits}
          goToNextPage={goToNextPage}
          goToPreviousPage={goToPreviousPage}
          toggleStar={toggleStar}
          starredResults={starredData.results}
          mainImages={mainImages}
          settings={settings}
          t={t}
          openFullSizeImage={openFullSizeImage}
          starredFolders={starredData.folders}
          moveToFolder={moveToFolder}
          updateStarredData={updateStarredData}
          ads={ads}
        />
      </div>
      <Footer t={t} />
      {showStarredPopup && (
        <StarredPopup
          starredResults={starredData.results}
          starredFolders={starredData.folders}
          createFolder={createFolder}
          moveToFolder={moveToFolder}
          setShowDeletePopup={setShowDeletePopup}
          setShowRenamePopup={setShowRenamePopup}
          setFolderToModify={setFolderToModify}
          setNewFolderName={setNewFolderName}
          closePopup={() => setShowStarredPopup(false)}
          t={t}
          settings={settings}
          toggleStar={toggleStar}
          mainImages={mainImages}
          openFullSizeImage={openFullSizeImage}
          updateStarredData={updateStarredData}
        />
      )}
      {showHistoryPopup && (
        <HistoryPopup
          searchHistory={searchHistory}
          clearHistory={clearHistory}
          handleSearch={handleSearch}
          closePopup={() => setShowHistoryPopup(false)}
          t={t}
        />
      )}
      {showDeletePopup && (
        <DeleteFolderPopup
          folderToDelete={folderToModify}
          onConfirm={() => {
            deleteFolder(folderToModify);
            setShowDeletePopup(false);
          }}
          onCancel={() => setShowDeletePopup(false)}
          t={t}
        />
      )}
      {showRenamePopup && (
        <RenameFolderPopup
          folderToRename={folderToModify}
          newFolderName={newFolderName}
          setNewFolderName={setNewFolderName}
          onConfirm={() => {
            renameFolder(folderToModify, newFolderName);
            setShowRenamePopup(false);
          }}
          onCancel={() => setShowRenamePopup(false)}
          t={t}
        />
      )}
      {showReverseSearchPopup && (
        <ReverseSearchPopup
          closePopup={() => setShowReverseSearchPopup(false)}
          settings={settings}
          t={t}
        />
      )}
      {showSettingsPopup && (
        <SettingsPopup
          settings={settings}
          updateSettings={updateSettings}
          closePopup={() => setShowSettingsPopup(false)}
          t={t}
        />
      )}
      {showLanguagePopup && (
        <LanguagePopup
          currentLanguage={currentLanguage}
          changeLanguage={changeLanguage}
          closePopup={() => setShowLanguagePopup(false)}
          t={t}
        />
      )}
      {showGalleryMode && (
        <GalleryMode
          results={results}
          mainImages={mainImages}
          closeGallery={() => setShowGalleryMode(false)}
          t={t}
          goToNextPage={goToNextPage}
          currentPage={currentPage}
          totalHits={totalHits}
          settings={settings}
        />
      )}
      {fullSizeImage && (
        <ImagePopup
          imageUrl={fullSizeImage}
          closePopup={() => setFullSizeImage(null)}
        />
      )}
    </div>
  );
}

function AppWrapper() {
  return (
    <SettingsProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </SettingsProvider>
  );
}

export default AppWrapper;