export const es = {
  searchPlaceholder: "Olfateando algo peludo y fantástico...",
  resultsFound: "Se encontraron aproximadamente {0} resultados",
  noResults: "Ups, no hay resultados en este mundo peludo.",
  loading: "Cargando...",
  previousButton: "Anterior",
  nextButton: "Siguiente",
  page: "Página {0}",
  starredResults: "Resultados favoritos",
  noStarredResults: "Aún no hay resultados favoritos.",
  searchHistory: "Historial de búsqueda",
  noSearchHistory: "Aún no hay historial de búsqueda.",
  clearHistory: "Borrar historial",
  deleteFolder: "Eliminar carpeta",
  deleteFolderConfirm: '¿Estás seguro de que quieres eliminar la carpeta "{0}" y todo su contenido?',
  yesDelete: "Sí, eliminar",
  cancel: "Cancelar",
  renameFolder: "Renombrar carpeta",
  rename: "Renombrar",
  newFolderName: "Nuevo nombre de carpeta",
  reverseImageSearch: "Búsqueda inversa de imágenes",
  uploadImage: "Subir imagen",
  changeImage: "Cambiar imagen",
  search: "Buscar",
  searchingVisualRealm: "Buscando...",
  searchResults: "Resultados de búsqueda",
  score: "Puntuación: {0}%",
  viewOriginal: "Ver original",
  settings: "Configuración",
  displaySettings: "Configuración de pantalla",
  showImages: "Mostrar imágenes",
  showImagesDesc: "Mostrar imágenes en miniatura en los resultados de búsqueda.",
  nsfwEffect: "Efecto NSFW",
  nsfwEffectDesc: "Aplicar un efecto visual al contenido potencialmente sensible.",
  colorTheme: "Tema de color",
  colorThemeDesc: "Elige entre temas de color oscuro y claro.",
  searchSettings: "Configuración de búsqueda",
  resultsPerPage: "Resultados por página:",
  resultsPerPageDesc: "Establece el número de resultados de búsqueda mostrados en cada página.",
  nsfwFilter: "Filtro NSFW",
  nsfwFilterDesc: "Filtrar contenido potencialmente sensible de los resultados de búsqueda.",
  saveSettings: "Guardar configuración",
  disableNsfwFilter: 'Desactivar filtro NSFW',
  enableNsfwFilter: 'Activar filtro NSFW',
  previous: 'Anterior',
  next: 'Siguiente',
  noDescription: " ",
  searching: "¡Buscando en el multiverso peludo!",
  viewStarredResults: "Ver resultados favoritos",
  folders: "Carpetas",
  unorganizedStars: "Favoritos sin organizar",
  noFolder: "Sin carpeta",
  createFolder: "Crear carpeta",
  viewSearchHistory: "Ver historial de búsqueda",
  hideImages: "Ocultar imágenes",
  galleryMode: "Modo galería",
  hideNsfwEffect: "Ocultar efecto NSFW",
  switchToBrightTheme: "Cambiar a tema claro",
  switchToDarkTheme: "Cambiar a tema oscuro",
  searchEngineSettings: "Configuración",
  changeLanguage: "Idioma",
  theme: "Tema",
  dark: "Oscuro",
  bright: "Claro",
  poweredByFluffy: "Impulsado por Fluffy.xyz creado por NoppesTheFolf",
  exportData: "Exportar datos",
  importData: "Importar datos",
  importWarning: "Advertencia: Importar datos sobrescribirá tus resultados favoritos y carpetas actuales. ¿Deseas continuar?",
  importSuccess: "¡Datos importados con éxito!",
  importError: "Error al importar datos. Por favor, verifica el archivo e intenta de nuevo.",
  noImagesAvailable: "No hay imágenes disponibles",
  allRightsReserved: 'Todos los derechos reservados.',
  termsOfService: 'Términos de servicio',
  privacyPolicy: 'Política de privacidad',
  tos_pp_date: 'Última actualización: 8 de agosto de 2024',
  tosContent: '1. Aceptación de los términos',
  tosContent1: 'Al acceder o utilizar SearchFur (el "Servicio"), usted acepta estar sujeto a estos Términos de servicio ("Términos"). Si no está de acuerdo con alguna parte de los términos, no puede acceder al Servicio.',
  tosContent2: '2. Descripción del servicio',
  tosContent3: 'SearchFur es un motor de búsqueda que permite a los usuarios buscar información en Internet. El Servicio puede almacenar cookies en los dispositivos de los usuarios para mantener el historial de búsqueda y los Resultados favoritos.',
  tosContent4: '3. Responsabilidades del usuario',
  tosContent5: '3.1. Usted acepta usar el Servicio solo para fines legales y de acuerdo con estos Términos.',
  tosContent6: '3.2. Usted es responsable de mantener la confidencialidad de cualquier información personal que proporcione mientras usa el Servicio.',
  tosContent7: '4. Propiedad intelectual',
  tosContent8: '4.1. El Servicio y su contenido original, características y funcionalidad son y seguirán siendo propiedad exclusiva de SearchFur y sus licenciantes.',
  tosContent9: '4.2. Los usuarios conservan todos los derechos sobre el contenido de sus búsquedas.',
  tosContent10: '5. Publicidad',
  tosContent11: '5.1. SearchFur puede mostrar anuncios como parte del Servicio. Estos anuncios ayudan a mantener y apoyar los aspectos gratuitos de nuestro Servicio.',
  tosContent12: '5.2. No utilizamos datos personales para dirigir anuncios. Todos los anuncios son contextuales basados en consultas de búsqueda o categorías generales.',
  tosContent13: '6. Funciones Pro',
  tosContent14: '6.1. SearchFur puede ofrecer funciones premium en el futuro como parte de un servicio de suscripción de pago ("Funciones Pro").',
  tosContent15: '6.2. Los términos para las Funciones Pro se proporcionarán por separado si y cuando dichas funciones estén disponibles.',
  tosContent16: '6.3. Las funciones gratuitas seguirán estando disponibles para todos los usuarios.',
  tosContent17: '7. Búsqueda inversa de imágenes',
  tosContent18: '7.1. SearchFur utiliza Fluffle.xyz para la funcionalidad de búsqueda inversa de imágenes. Al usar esta función, aceptas los términos de servicio y la política de privacidad de Fluffle.',
  tosContent19: '7.2. Cuando usas la función de búsqueda inversa de imágenes, tu imagen se almacenará temporalmente en los servidores de Fluffle para su procesamiento. La imagen se elimina inmediatamente después del procesamiento, excepto cuando creas un enlace permanente al resultado de búsqueda.',
  tosContent20: '7.3. SearchFur no es responsable de las prácticas de privacidad o el contenido de Fluffle.xyz. Te animamos a revisar la política de privacidad de Fluffle para obtener más información.',
  tosContent21: '8. Limitación de responsabilidad',
  tosContent22: '8.1. SearchFur no será responsable de ningún daño indirecto, incidental, especial, consecuente o punitivo resultante de tu acceso o uso, o incapacidad de acceder o usar, el Servicio.',
  tosContent23: '9. Cambios en los términos',
  tosContent24: '9.1. Nos reservamos el derecho de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es material, proporcionaremos un aviso de al menos 30 días antes de que los nuevos términos entren en vigencia.',
  privacyContent: '1. Introducción',
  privacyContent1: 'Esta Política de Privacidad describe cómo SearchFur ("nosotros", "nuestro" o "nos") recopila, usa y comparte información sobre ti cuando usas nuestro servicio de motor de búsqueda. Estamos comprometidos a proteger tu privacidad y asegurar que tengas una experiencia positiva en nuestro sitio web.',
  privacyContent2: '2. Información que recopilamos',
  privacyContent3: '2.1. Consultas de búsqueda: Recopilamos y almacenamos las consultas de búsqueda que ingresas en SearchFur. Esta información se recopila de forma anónima y no puede vincularse a usuarios individuales.',
  privacyContent4: '2.2. Cookies: Usamos cookies para almacenar tu historial de búsqueda y Resultados favoritos en tu dispositivo. Esta información permanece en tu dispositivo y no es recopilada ni accedida por SearchFur.',
  privacyContent5: '3. Cómo usamos la información',
  privacyContent6: '3.1. Usamos las consultas de búsqueda anonimizadas para mejorar nuestro servicio de motor de búsqueda y proporcionar resultados de búsqueda más relevantes.',
  privacyContent7: '3.2. No usamos la información almacenada en cookies (historial de búsqueda y Resultados favoritos) ya que esta información permanece únicamente en tu dispositivo.',
  privacyContent8: '4. Publicidad',
  privacyContent9: '4.1. Podemos mostrar anuncios como parte de nuestro Servicio. Estos anuncios se basan en el contexto de tus consultas de búsqueda o categorías generales.',
  privacyContent10: '4.2. No recopilamos ni usamos información personal para la orientación de anuncios. Todos los anuncios se sirven basados en el contenido de tus búsquedas o temas generales, no en perfiles de usuario o datos personales.',
  privacyContent11: '5. Búsqueda inversa de imágenes',
  privacyContent12: '5.1. Para la funcionalidad de búsqueda inversa de imágenes, SearchFur utiliza Fluffle.xyz, un servicio de terceros.',
  privacyContent13: '5.2. Cuando usas la función de búsqueda inversa de imágenes: 1. Tu imagen se almacena temporalmente en los servidores de Fluffle para su procesamiento. 2. La imagen se elimina inmediatamente después del procesamiento, excepto cuando creas un enlace permanente al resultado de búsqueda. 3. Fluffle crea una huella digital de tu imagen para comparación pero no almacena esta huella digital. 4. Fluffle registra cierta información no personal sobre tu solicitud con fines de rendimiento y seguridad.',
  privacyContent14: '5.3. Fluffle utiliza la plataforma de análisis web de Cloudflare, que no recopila información personal, no rastrea el comportamiento de navegación ni utiliza cookies.',
  privacyContent15: '5.4. Para obtener información más detallada, consulta la política de privacidad de Fluffle.',
  privacyContent16: '6. Funciones Pro',
  privacyContent17: '6.1. Si introducimos Funciones Pro en el futuro, podríamos recopilar información adicional necesaria para proporcionar estos servicios, como información de cuenta y detalles de pago. 6.2. Cualquier recopilación de datos adicional relacionada con las Funciones Pro se comunicará claramente antes de la implementación, y los usuarios tendrán la opción de optar por estas funciones.',
  privacyContent18: '7. Compartir y divulgar información',
  privacyContent19: '7.1. No vendemos, comerciamos ni transferimos de otra manera tu información de identificación personal a terceros. 7.2. Podemos divulgar información cuando lo requiera la ley o para proteger nuestros derechos, privacidad, seguridad, propiedad o los de otros.',
  privacyContent20: '8. Retención de datos',
  privacyContent21: 'Retenemos las consultas de búsqueda anonimizadas durante el tiempo necesario para cumplir con los propósitos descritos en esta Política de Privacidad, a menos que un período de retención más largo sea requerido o permitido por la ley.',
  privacyContent22: '9. Tus opciones',
  privacyContent23: '9.1. Puedes optar por desactivar las cookies a través de la configuración de tu navegador. Sin embargo, esto puede afectar la funcionalidad de ciertas características como el historial de búsqueda y los Resultados favoritos.',
  privacyContent24: '9.2. Como no recopilamos información de identificación personal, no podemos proporcionar acceso individual o eliminación de datos de búsqueda.',
  privacyContent25: '10. Privacidad de los niños',
  privacyContent26: 'Nuestro Servicio no está dirigido a personas menores de 13 años. No recopilamos a sabiendas información de identificación personal de niños menores de 13 años. Si eres padre o tutor y sabes que tu hijo nos ha proporcionado información personal, por favor contáctanos.',
  privacyContent27: '11. Cambios en esta Política de Privacidad',
  privacyContent28: 'Podemos actualizar nuestra Política de Privacidad de vez en cuando. Te notificaremos de cualquier cambio publicando la nueva Política de Privacidad en esta página y actualizando la fecha de "Última actualización" en la parte superior de esta Política de Privacidad.',
  privacyContent29: '12. Seguridad',
  privacyContent30: 'Estamos comprometidos a garantizar que tu información esté segura. Hemos implementado procedimientos físicos, electrónicos y de gestión adecuados para salvaguardar y asegurar la información que recopilamos en línea.',
  delete: 'Eliminar',
  availableinpro: 'Cambiar los resultados por página SOLO está disponible en la versión PRO',
  madebykeyfox: 'Hecho por Keyfox/Jianhu con ❤️'
}