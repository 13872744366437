export const ru = {
    searchPlaceholder: "Вынюхиваем что-то пушистое...",
    resultsFound: "Найдено примерно {0} результатов",
    noResults: "Ой-ой, в этом пушистом мире нет результатов.",
    loading: "Загрузка...",
    previousButton: "Предыдущая",
    nextButton: "Следующая",
    page: "Страница {0}",
    starredResults: "Избранные результаты",
    noStarredResults: "Пока нет избранных результатов.",
    searchHistory: "История поиска",
    noSearchHistory: "Пока нет истории поиска.",
    clearHistory: "Очистить историю",
    deleteFolder: "Удалить папку",
    deleteFolderConfirm: 'Вы уверены, что хотите удалить папку "{0}" и все её содержимое?',
    yesDelete: "Да, удалить",
    cancel: "Отмена",
    renameFolder: "Переименовать папку",
    rename: "Переименовать",
    newFolderName: "Новое имя папки",
    reverseImageSearch: "Поиск по изображению",
    uploadImage: "Загрузить изображение",
    changeImage: "Изменить изображение",
    search: "Поиск",
    searchingVisualRealm: "Поиск...",
    searchResults: "Результаты поиска",
    score: "Сходство: {0}%",
    viewOriginal: "Посмотреть оригинал",
    settings: "Настройки",
    displaySettings: "Настройки отображения",
    showImages: "Показывать изображения",
    showImagesDesc: "Отображать миниатюры изображений в результатах поиска.",
    nsfwEffect: "NSFW эффект",
    nsfwEffectDesc: "Применять визуальный эффект к потенциально чувствительному контенту.",
    colorTheme: "Цветовая тема",
    colorThemeDesc: "Выберите между темной и светлой цветовыми схемами.",
    searchSettings: "Настройки поиска",
    resultsPerPage: "Результатов на странице:",
    resultsPerPageDesc: "Установите количество результатов поиска, отображаемых на каждой странице.",
    nsfwFilter: "NSFW фильтр",
    nsfwFilterDesc: "Отфильтровывать потенциально чувствительный контент из результатов поиска.",
    saveSettings: "Сохранить настройки",
    disableNsfwFilter: 'Отключить NSFW фильтр',
    enableNsfwFilter: 'Включить NSFW фильтр',
    previous: 'Предыдущая',
    next: 'Следующая',
    noDescription: " ",
    searching: "Ищем в пушистой мультивселенной!",
    viewStarredResults: "Посмотреть избранные результаты",
    folders: "Папки",
    unorganizedStars: "Неорганизованные избранные",
    noFolder: "Без папки",
    createFolder: "Создать папку",
    viewSearchHistory: "Посмотреть историю поиска",
    hideImages: "Скрыть изображения",
    galleryMode: "Режим галереи",
    hideNsfwEffect: "Скрыть NSFW эффект",
    switchToBrightTheme: "Переключиться на светлую тему",
    switchToDarkTheme: "Переключиться на темную тему",
    searchEngineSettings: "Настройки",
    changeLanguage: "Язык",
    theme: "Тема",
    dark: "Темная",
    bright: "Светлая",
    poweredByFluffy: "Работает на Fluffy.xyz, созданном NoppesTheFolf",
    exportData: "Экспорт данных",
    importData: "Импорт данных",
    importWarning: "Внимание: Импорт данных перезапишет ваши текущие избранные результаты и папки. Вы хотите продолжить?",
    importSuccess: "Данные успешно импортированы!",
    importError: "Ошибка при импорте данных. Пожалуйста, проверьте файл и попробуйте снова.",
    noImagesAvailable: "Нет доступных изображений",
    allRightsReserved: 'Все права защищены.',
    termsOfService: 'Условия использования',
    privacyPolicy: 'Политика конфиденциальности',
    tos_pp_date: 'Последнее обновление: 8 августа 2024 г.',
    tosContent: '1. Принятие условий',
    tosContent1: 'Получая доступ к SearchFur ("Сервис") или используя его, вы соглашаетесь соблюдать настоящие Условия использования ("Условия"). Если вы не согласны с какой-либо частью условий, вы не можете получить доступ к Сервису.',
    tosContent2: '2. Описание сервиса',
    tosContent3: 'SearchFur - это поисковая система, позволяющая пользователям искать информацию в интернете. Сервис может хранить куки на устройствах пользователей для сохранения истории поиска и Избранных результатов.',
    tosContent4: '3. Обязанности пользователя',
    tosContent5: '3.1. Вы соглашаетесь использовать Сервис только в законных целях и в соответствии с настоящими Условиями.',
    tosContent6: '3.2. Вы несете ответственность за сохранение конфиденциальности любой личной информации, которую вы предоставляете при использовании Сервиса.',
    tosContent7: '4. Интеллектуальная собственность',
    tosContent8: '4.1. Сервис и его оригинальный контент, функции и функциональность являются и будут оставаться исключительной собственностью SearchFur и его лицензиаров.',
    tosContent9: '4.2. Пользователи сохраняют все права на содержание своих поисковых запросов.',
    tosContent10: '5. Реклама',
    tosContent11: '5.1. SearchFur может отображать рекламу как часть Сервиса. Эта реклама помогает поддерживать и сохранять бесплатные аспекты нашего Сервиса.',
    tosContent12: '5.2. Мы не используем персональные данные для таргетинга рекламы. Вся реклама контекстуальна и основана на поисковых запросах или общих категориях.',
    tosContent13: '6. Про-функции',
    tosContent14: '6.1. SearchFur может предложить премиум-функции в будущем как часть платной подписки ("Про-функции").',
    tosContent15: '6.2. Условия для Про-функций будут предоставлены отдельно, если и когда такие функции станут доступны.',
    tosContent16: '6.3. Бесплатные функции останутся доступными для всех пользователей.',
    tosContent17: '7. Поиск по изображению',
    tosContent18: '7.1. SearchFur использует Fluffle.xyz для функциональности поиска по изображению. Используя эту функцию, вы соглашаетесь с условиями использования и политикой конфиденциальности Fluffle.',
    tosContent19: '7.2. Когда вы используете функцию поиска по изображению, ваше изображение временно хранится на серверах Fluffle для обработки. Изображение удаляется сразу после обработки, за исключением случаев, когда вы создаете постоянную ссылку на результат поиска.',
    tosContent20: '7.3. SearchFur не несет ответственности за политику конфиденциальности или содержание Fluffle.xyz. Мы рекомендуем вам ознакомиться с политикой конфиденциальности Fluffle для получения дополнительной информации.',
    tosContent21: '8. Ограничение ответственности',
    tosContent22: '8.1. SearchFur не несет ответственности за любые косвенные, случайные, специальные, последующие или штрафные убытки, возникшие в результате вашего доступа к Сервису или его использования, или невозможности доступа или использования.',
    tosContent23: '9. Изменения в условиях',
    tosContent24: '9.1. Мы оставляем за собой право изменять или заменять эти Условия в любое время. Если пересмотр является существенным, мы предоставим уведомление не менее чем за 30 дней до вступления в силу любых новых условий.',
    privacyContent: '1. Введение',
    privacyContent1: 'Эта Политика конфиденциальности описывает, как SearchFur ("мы", "наш" или "нас") собирает, использует и делится информацией о вас при использовании нашего сервиса поисковой системы. Мы стремимся защитить вашу конфиденциальность и обеспечить вам положительный опыт на нашем веб-сайте.',
    privacyContent2: '2. Информация, которую мы собираем',
    privacyContent3: '2.1. Поисковые запросы: Мы собираем и храним поисковые запросы, которые вы вводите в SearchFur. Эта информация собирается анонимно и не может быть связана с отдельными пользователями.',
    privacyContent4: '2.2. Куки: Мы используем куки для хранения вашей истории поиска и Избранных результатов на вашем устройстве. Эта информация остается на вашем устройстве и не собирается и не доступна SearchFur.',
    privacyContent5: '3. Как мы используем информацию',
    privacyContent6: '3.1. Мы используем анонимизированные поисковые запросы для улучшения нашего сервиса поисковой системы и предоставления более релевантных результатов поиска.',
    privacyContent7: '3.2. Мы не используем информацию, хранящуюся в куки (история поиска и Избранные результаты), так как эта информация остается исключительно на вашем устройстве.',
    privacyContent8: '4. Реклама',
    privacyContent9: '4.1. Мы можем отображать рекламу как часть нашего Сервиса. Эта реклама основана на контексте ваших поисковых запросов или общих категориях.',
    privacyContent10: '4.2. Мы не собираем и не используем личную информацию для таргетинга рекламы. Вся реклама предоставляется на основе содержания ваших поисков или общих тем, а не на профилях пользователей или личных данных.',
    privacyContent11: '5. Поиск по изображению',
    privacyContent12: '5.1. Для функциональности поиска по изображению SearchFur использует Fluffle.xyz, сторонний сервис.',
    privacyContent13: '5.2. Когда вы используете функцию поиска по изображению: 1. Ваше изображение временно хранится на серверах Fluffle для обработки. 2. Изображение удаляется сразу после обработки, за исключением случаев, когда вы создаете постоянную ссылку на результат поиска. 3. Fluffle создает отпечаток вашего изображения для сравнения, но не хранит этот отпечаток. 4. Fluffle регистрирует определенную неперсональную информацию о вашем запросе для целей производительности и безопасности.',
    privacyContent14: '5.3. Fluffle использует платформу веб-аналитики Cloudflare, которая не собирает личную информацию, не отслеживает поведение просмотра и не использует куки.',
    privacyContent15: '5.4. Для более подробной информации, пожалуйста, обратитесь к политике конфиденциальности Fluffle.',
    privacyContent16: '6. Про-функции',
    privacyContent17: '6.1. Если мы введем Про-функции в будущем, мы можем собирать дополнительную информацию, необходимую для предоставления этих услуг, такую как информация об аккаунте и детали оплаты. 6.2. Любой дополнительный сбор данных, связанный с Про-функциями, будет четко сообщен перед реализацией, и пользователи будут иметь выбор присоединиться к этим функциям.',
    privacyContent18: '7. Обмен информацией и раскрытие',
    privacyContent19: '7.1. Мы не продаем, не обмениваем и иным образом не передаем вашу личную идентифицируемую информацию сторонним лицам. 7.2. Мы можем раскрывать информацию, когда это требуется по закону или для защиты наших прав, конфиденциальности, безопасности, собственности или других лиц.',
    privacyContent20: '8. Хранение данных',
    privacyContent21: 'Мы храним анонимизированные поисковые запросы столько времени, сколько необходимо для выполнения целей, изложенных в этой Политике конфиденциальности, если более длительный период хранения не требуется или не разрешен законом.',
    privacyContent22: '9. Ваш выбор',
  privacyContent23: '9.1. Вы можете отключить куки через настройки вашего браузера. Однако это может повлиять на функциональность некоторых функций, таких как история поиска и Избранные результаты.',
  privacyContent24: '9.2. Поскольку мы не собираем личную идентифицируемую информацию, мы не можем предоставить индивидуальный доступ или удаление данных поиска.',
  privacyContent25: '10. Конфиденциальность детей',
  privacyContent26: 'Наш Сервис не предназначен для лиц младше 13 лет. Мы сознательно не собираем личную идентифицируемую информацию от детей младше 13 лет. Если вы родитель или опекун и вам стало известно, что ваш ребенок предоставил нам личную информацию, пожалуйста, свяжитесь с нами.',
  privacyContent27: '11. Изменения в этой Политике конфиденциальности',
  privacyContent28: 'Мы можем время от времени обновлять нашу Политику конфиденциальности. Мы уведомим вас о любых изменениях, разместив новую Политику конфиденциальности на этой странице и обновив дату "Последнее обновление" в верхней части этой Политики конфиденциальности.',
  privacyContent29: '12. Безопасность',
  privacyContent30: 'Мы стремимся обеспечить безопасность вашей информации. Мы внедрили подходящие физические, электронные и управленческие процедуры для защиты и обеспечения безопасности информации, которую мы собираем онлайн.',
  delete: 'Удалить',
  availableinpro: 'Изменение количества результатов на странице доступно ТОЛЬКО в PRO версии',
  madebykeyfox: 'Сделано Keyfox/Jianhu с ❤️'
}