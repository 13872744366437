export function isNSFW(content) {
  if (!content) return false;
  const lowerContent = content.toLowerCase();
  const nsfwKeywords = ['nsfw', 'adult', 'xxx', 'porn', 'explicit', 'sex']; // Add more keywords as needed
  return nsfwKeywords.some(keyword => lowerContent.includes(keyword));
}

export async function fetchMainImage(url) {
  try {
    const corsAnywhereUrl = 'https://oyster-app-qp8wj.ondigitalocean.app/';
    const response = await fetch(corsAnywhereUrl + url);
    const html = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    
    const ogImage = doc.querySelector('meta[property="og:image"]');
    if (ogImage) {
      const imageUrl = ogImage.getAttribute('content');
      return {
        thumbnail: imageUrl,
        fullSize: imageUrl
      };
    }
    
    const images = Array.from(doc.getElementsByTagName('img'));
    const largeImage = images.find(img => {
      const width = parseInt(img.getAttribute('width') || '0');
      const height = parseInt(img.getAttribute('height') || '0');
      return width > 200 && height > 200;
    });
    
    if (largeImage) {
      return {
        thumbnail: largeImage.src,
        fullSize: largeImage.src
      };
    }
    
    return null;
  } catch (error) {
    console.error('Error fetching main image:', error);
    return null;
  }
}

export const fetchAds = async () => {
  // In a real-world scenario, you would fetch ads from an ad network or your own ad server
  // For this example, we'll return some mock ads
  return [
    /*{
      title: "Premium Web Hosting",
      description: "Fast, secure, and reliable web hosting for your projects.",
      link: "https://example.com/webhosting",
      mediaType: "image",
      mediaUrl: "ad-assets/web-hosting-ad.jpg"
    },*/
    {
      title: "Advertise on SearchFur!",
      description: "DM me in the FA or Discord if you are interested!",
      link: "https://www.furaffinity.net/newpm/jianhu",
      mediaType: "gif",
      mediaUrl: "https://search-fur-image-server.vercel.app/images/Psa4tJt.gif"
    },
    {
      title: "Advertise on SearchFur!",
      description: "DM me in the FA or Discord if you are interested!",
      link: "https://www.furaffinity.net/newpm/jianhu",
      mediaType: "gif",
      mediaUrl: "https://search-fur-image-server.vercel.app/images/Psa4tJt.gif"
    },
    {
      title: "Advertise on SearchFur!",
      description: "DM me in the FA or Discord if you are interested!",
      link: "https://www.furaffinity.net/newpm/jianhu",
      mediaType: "gif",
      mediaUrl: "https://search-fur-image-server.vercel.app/images/Psa4tJt.gif"
    },
    /*{
      title: "Cloud Storage Solutions",
      description: "Secure and scalable cloud storage for your data.",
      link: "https://example.com/cloud-storage",
      mediaType: "video",
      mediaUrl: "https://example.com/videos/cloud-storage-ad.mp4"
    }*/
  ];
};