import React from 'react';
import { motion } from 'framer-motion';

export function PrivacyPopup({ closePopup, t }) {
  return (
    <motion.div
      className="popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2>{t('privacyPolicy')}</h2>
        <motion.button 
          className="close-popup" 
          onClick={closePopup}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          ×
        </motion.button>
        <div className="privacy-content">
          {/* Add your Privacy Policy content here */}
          <p>{t('tos_pp_date')}</p>
          <p>{t('privacyContent')}</p>
          <p>{t('privacyContent1')}</p>
          <p>{t('privacyContent2')}</p>
          <p>{t('privacyContent3')}</p>
          <p>{t('privacyContent4')}</p>
          <p>{t('privacyContent5')}</p>
          <p>{t('privacyContent6')}</p>
          <p>{t('privacyContent7')}</p>
          <p>{t('privacyContent8')}</p>
          <p>{t('privacyContent9')}</p>
          <p>{t('privacyContent10')}</p>
          <p>{t('privacyContent11')}</p>
          <p>{t('privacyContent12')}</p>
          <p>{t('privacyContent13')}</p>
          <p>{t('privacyContent14')}</p>
          <p>{t('privacyContent15')}</p>
          <p>{t('privacyContent16')}</p>
          <p>{t('privacyContent17')}</p>
          <p>{t('privacyContent18')}</p>
          <p>{t('privacyContent19')}</p>
          <p>{t('privacyContent20')}</p>
          <p>{t('privacyContent21')}</p>
          <p>{t('privacyContent22')}</p>
          <p>{t('privacyContent23')}</p>
          <p>{t('privacyContent24')}</p>
          <p>{t('privacyContent25')}</p>
          <p>{t('privacyContent26')}</p>
          <p>{t('privacyContent27')}</p>
          <p>{t('privacyContent28')}</p>
          <p>{t('privacyContent29')}</p>
          <p>{t('privacyContent30')}</p>
        </div>
      </motion.div>
    </motion.div>
  );
}