import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { reverseImageSearch } from '../../utils/api';

export function ReverseSearchPopup({ closePopup, settings, t }) {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [reverseSearchResults, setReverseSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage(file);
    }
  };

  const handleReverseSearch = async (e) => {
    e.preventDefault();
    if (!uploadedImage) return;

    setIsLoading(true);
    setError(null);

    try {
      const results = await reverseImageSearch(uploadedImage, !settings.nsfwFilter);
      setReverseSearchResults(results);
    } catch (err) {
      console.error('An error occurred during reverse image search:', err);
      setError(t('reverseSearchError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      className="popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content reverse-search-popup"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2>{t('reverseImageSearch')}</h2>
        <h5>{t('poweredByFluffy')}</h5>
        <motion.button 
          className="close-popup" 
          onClick={closePopup}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          ×
        </motion.button>
        <div className="upload-area">
          <input 
            type="file" 
            accept="image/*" 
            onChange={handleFileUpload} 
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <motion.button 
            className="upload-button" 
            onClick={() => fileInputRef.current.click()}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {uploadedImage ? t('changeImage') : t('uploadImage')}
          </motion.button>
          {uploadedImage && (
            <motion.div 
              className="uploaded-image-preview"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <img 
                src={URL.createObjectURL(uploadedImage)} 
                alt={t('uploaded')} 
              />
            </motion.div>
          )}
        </div>
        {uploadedImage && (
          <motion.button 
            className="search-button" 
            onClick={handleReverseSearch}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {t('search')}
          </motion.button>
        )}
        {isLoading && <p className="loading">{t('searchingVisualRealm')}</p>}
        {error && <p className="error">{error}</p>}
        {reverseSearchResults && (
          <motion.div 
            className="reverse-search-results"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <h3>{t('searchResults')}</h3>
            <div className="results-grid">
              {reverseSearchResults.map((result, index) => (
                <motion.div 
                  key={index} 
                  className="reverse-search-result"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="result-image">
                    <img src={result.thumbnail.location} alt={t('resultImage')} />
                  </div>
                  <div className="result-info">
                    <p className="result-score">{t('score', (result.score * 100).toFixed(2))}</p>
                    <p className="result-platform">{result.platform}</p>
                    <motion.a 
                      href={result.location} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="view-original"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {t('viewOriginal')}
                    </motion.a>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
}