import React, { useState } from 'react';
import { motion } from 'framer-motion';

export function SettingsPopup({ settings, updateSettings, closePopup, t }) {
  const [tempSettings, setTempSettings] = useState(settings);

  const handleChange = (setting, value) => {
    setTempSettings(prev => ({ ...prev, [setting]: value }));
  };

  const saveSettings = () => {
    Object.entries(tempSettings).forEach(([key, value]) => {
      updateSettings(key, value);
    });
    closePopup();
  };

  return (
    <motion.div
      className="popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content settings-popup"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2 className="settings-title">{t('searchEngineSettings')}</h2>
        <motion.button 
          className="close-popup" 
          onClick={closePopup}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          ×
        </motion.button>
        
        <div className="settings-section">
          <h3>{t('displaySettings')}</h3>
          <div className="settings-group">
            <label className="switch">
              <input 
                type="checkbox" 
                checked={tempSettings.showImages} 
                onChange={(e) => handleChange('showImages', e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
            <div className="setting-description">
              <h4>{t('showImages')}</h4>
              <p>{t('showImagesDesc')}</p>
            </div>
          </div>

          <div className="settings-group">
            <label className="switch">
              <input 
                type="checkbox" 
                checked={tempSettings.showNsfwEffect} 
                onChange={(e) => handleChange('showNsfwEffect', e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
            <div className="setting-description">
              <h4>{t('nsfwEffect')}</h4>
              <p>{t('nsfwEffectDesc')}</p>
            </div>
          </div>

          <div className="settings-group">
            <label>
              {t('theme')}:
              <select 
                value={tempSettings.theme} 
                onChange={(e) => handleChange('theme', e.target.value)}
              >
                <option value="dark">{t('dark')}</option>
                <option value="bright">{t('bright')}</option>
              </select>
            </label>
            <div className="setting-description">
              <h4>{t('colorTheme')}</h4>
              <p>{t('colorThemeDesc')}</p>
            </div>
          </div>
        </div>

        <div className="settings-section">
          <h3>{t('searchSettings')}</h3>
          <div className="settings-group">
            {/*<label>
              {t('resultsPerPage')}:
              <input 
                type="number" 
                value={tempSettings.resultsPerPage} 
                onChange={(e) => handleChange('resultsPerPage', parseInt(e.target.value))}
                min="1"
                max="100"
              />
            </label>*/}
            <div className="setting-description">
              <h4>{t('availableinpro')}</h4>
              <h4>{t('resultsPerPage')}</h4>
              <p>{t('resultsPerPageDesc')}</p>
            </div>
          </div>

          <div className="settings-group">
            <label className="switch">
              <input 
                type="checkbox" 
                checked={tempSettings.nsfwFilter} 
                onChange={(e) => handleChange('nsfwFilter', e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
            <div className="setting-description">
              <h4>{t('nsfwFilter')}</h4>
              <p>{t('nsfwFilterDesc')}</p>
            </div>
          </div>
        </div>

        <motion.button 
          className="save-settings" 
          onClick={saveSettings}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {t('saveSettings')}
        </motion.button>
      </motion.div>
    </motion.div>
  );
}