export const ja = {
    searchPlaceholder: "ふわふわな何かを嗅ぎ出し中...",
    resultsFound: "約 {0} 件の結果が見つかりました",
    noResults: "あらら、このふわふわな世界に結果が見つかりませんでした。",
    loading: "読み込み中...",
    previousButton: "前へ",
    nextButton: "次へ",
    page: "ページ {0}",
    starredResults: "お気に入りの結果",
    noStarredResults: "まだお気に入りの結果はありません。",
    searchHistory: "検索履歴",
    noSearchHistory: "まだ検索履歴はありません。",
    clearHistory: "履歴を消去",
    deleteFolder: "フォルダを削除",
    deleteFolderConfirm: 'フォルダ "{0}" とその中身をすべて削除してもよろしいですか？',
    yesDelete: "はい、削除します",
    cancel: "キャンセル",
    renameFolder: "フォルダ名を変更",
    rename: "名前を変更",
    newFolderName: "新しいフォルダ名",
    reverseImageSearch: "画像検索",
    uploadImage: "画像をアップロード",
    changeImage: "画像を変更",
    search: "検索",
    searchingVisualRealm: "検索中...",
    searchResults: "検索結果",
    score: "類似度: {0}%",
    viewOriginal: "元の画像を表示",
    settings: "設定",
    displaySettings: "表示設定",
    showImages: "画像を表示",
    showImagesDesc: "検索結果にサムネイル画像を表示します。",
    nsfwEffect: "NSFWエフェクト",
    nsfwEffectDesc: "センシティブなコンテンツに視覚効果を適用します。",
    colorTheme: "カラーテーマ",
    colorThemeDesc: "ダークテーマとライトテーマを選択できます。",
    searchSettings: "検索設定",
    resultsPerPage: "1ページあたりの結果数:",
    resultsPerPageDesc: "各ページに表示される検索結果の数を設定します。",
    nsfwFilter: "NSFWフィルター",
    nsfwFilterDesc: "検索結果からセンシティブなコンテンツをフィルタリングします。",
    saveSettings: "設定を保存",
    disableNsfwFilter: 'NSFWフィルターを無効にする',
    enableNsfwFilter: 'NSFWフィルターを有効にする',
    previous: '前へ',
    next: '次へ',
    noDescription: " ",
    searching: "ふわふわマルチバースを検索中！",
    viewStarredResults: "お気に入りの結果を表示",
    folders: "フォルダ",
    unorganizedStars: "整理されていないお気に入り",
    noFolder: "フォルダなし",
    createFolder: "フォルダを作成",
    viewSearchHistory: "検索履歴を表示",
    hideImages: "画像を隠す",
    galleryMode: "ギャラリーモード",
    hideNsfwEffect: "NSFWエフェクトを隠す",
    switchToBrightTheme: "ライトテーマに切り替え",
    switchToDarkTheme: "ダークテーマに切り替え",
    searchEngineSettings: "設定",
    changeLanguage: "言語",
    theme: "テーマ",
    dark: "ダーク",
    bright: "ライト",
    poweredByFluffy: "NoppesTheFolf作のFluffy.xyzによって提供",
    exportData: "データをエクスポート",
    importData: "データをインポート",
    importWarning: "警告：データをインポートすると、現在のお気に入りの結果とフォルダが上書きされます。続行しますか？",
    importSuccess: "データのインポートに成功しました！",
    importError: "データのインポート中にエラーが発生しました。ファイルを確認して再試行してください。",
    noImagesAvailable: "利用可能な画像はありません",
    allRightsReserved: 'All rights reserved.',
    termsOfService: '利用規約',
    privacyPolicy: 'プライバシーポリシー',
    tos_pp_date: '最終更新日: 2024年8月8日',
    tosContent: '1. 規約の同意',
    tosContent1: 'SearchFur（以下「本サービス」）にアクセスまたは使用することにより、あなたはこの利用規約（以下「本規約」）に拘束されることに同意するものとします。本規約のいかなる部分にも同意しない場合は、本サービスにアクセスすることはできません。',
    tosContent2: '2. サービスの説明',
    tosContent3: 'SearchFurは、ユーザーがインターネット上で情報を検索できるようにする検索エンジンです。本サービスは、検索履歴とお気に入りの結果を維持するために、ユーザーのデバイスにクッキーを保存する場合があります。',
    tosContent4: '3. ユーザーの責任',
    tosContent5: '3.1. あなたは、本サービスを合法的な目的にのみ使用し、本規約を遵守することに同意するものとします。',
    tosContent6: '3.2. あなたは、本サービスの使用中に提供する個人情報の機密性を維持する責任があります。',
    tosContent7: '4. 知的財産権',
    tosContent8: '4.1. 本サービスおよびそのオリジナルコンテンツ、機能、機能性は、現在も将来もSearchFurとそのライセンサーの独占的財産です。',
    tosContent9: '4.2. ユーザーは、自身の検索内容に関するすべての権利を保持します。',
    tosContent10: '5. 広告',
    tosContent11: '5.1. SearchFurは、本サービスの一部として広告を表示する場合があります。これらの広告は、当社のサービスの無料部分をサポートし、維持するのに役立ちます。',
    tosContent12: '5.2. 当社は、広告のターゲティングに個人データを使用しません。すべての広告は、検索クエリや一般的なカテゴリに基づくコンテキスト広告です。',
    tosContent13: '6. プロ機能',
    tosContent14: '6.1. SearchFurは、将来的に有料サブスクリプションサービスの一部としてプレミアム機能（以下「プロ機能」）を提供する場合があります。',
    tosContent15: '6.2. プロ機能の条件は、そのような機能が利用可能になった場合に別途提供されます。',
    tosContent16: '6.3. 無料機能は引き続きすべてのユーザーに利用可能です。',
    tosContent17: '7. 画像検索',
    tosContent18: '7.1. SearchFurは、画像検索機能にFluffle.xyzを使用しています。この機能を使用することにより、あなたはFluffleの利用規約とプライバシーポリシーに同意することになります。',
    tosContent19: '7.2. 画像検索機能を使用する際、あなたの画像は処理のためにFluffleのサーバーに一時的に保存されます。検索結果への永続的なリンクを作成しない限り、画像は処理後すぐに削除されます。',
    tosContent20: '7.3. SearchFurは、Fluffle.xyzのプライバシー慣行やコンテンツに対して責任を負いません。詳細については、Fluffleのプライバシーポリシーを確認することをお勧めします。',
    tosContent21: '8. 責任の制限',
    tosContent22: '8.1. SearchFurは、本サービスへのアクセス、使用、またはアクセスや使用の不能によって生じた間接的、偶発的、特別、結果的、または懲罰的損害に対して責任を負わないものとします。',
    tosContent23: '9. 規約の変更',
    tosContent24: '9.1. 当社は、いつでも本規約を修正または置き換える権利を留保します。改訂が重要な場合、新しい条件が発効する少なくとも30日前に通知を行います。',
    privacyContent: '1. はじめに',
    privacyContent1: 'このプライバシーポリシーは、SearchFur（以下「当社」、「当社の」または「私たち」）が、あなたが当社の検索エンジンサービスを使用する際にあなたに関する情報をどのように収集、使用、共有するかを説明しています。当社は、あなたのプライバシーを保護し、ウェブサイトでの良好な体験を確保することに努めています。',
    privacyContent2: '2. 当社が収集する情報',
    privacyContent3: '2.1. 検索クエリ：当社は、あなたがSearchFurに入力する検索クエリを収集し、保存します。この情報は匿名で収集され、個々のユーザーと関連付けることはできません。',
    privacyContent4: '2.2. クッキー：当社は、あなたの検索履歴とお気に入りの結果をあなたのデバイスに保存するためにクッキーを使用します。この情報はあなたのデバイスに残り、SearchFurによって収集またはアクセスされることはありません。',
    privacyContent5: '3. 情報の使用方法',
    privacyContent6: '3.1. 当社は、匿名化された検索クエリを使用して、検索エンジンサービスを改善し、より関連性の高い検索結果を提供します。',
    privacyContent7: '3.2. 当社は、クッキーに保存された情報（検索履歴とお気に入りの結果）を使用しません。この情報はあなたのデバイスにのみ残ります。',
    privacyContent8: '4. 広告',
    privacyContent9: '4.1. 当社は、サービスの一部として広告を表示する場合があります。これらの広告は、あなたの検索クエリのコンテキストや一般的なカテゴリに基づいています。',
    privacyContent10: '4.2. 当社は、広告のターゲティングに個人情報を収集または使用しません。すべての広告は、ユーザープロフィールや個人データではなく、あなたの検索内容や一般的なトピックに基づいて提供されます。',
    privacyContent11: '5. 画像検索',
    privacyContent12: '5.1. SearchFurは、画像検索機能にサードパーティサービスであるFluffle.xyzを使用しています。',
    privacyContent13: '5.2. 画像検索機能を使用する際：1. あなたの画像は処理のためにFluffleのサーバーに一時的に保存されます。2. 検索結果への永続的なリンクを作成しない限り、画像は処理後すぐに削除されます。3. Fluffleは比較のためにあなたの画像の指紋を作成しますが、この指紋は保存しません。4. Fluffleは、パフォーマンスとセキュリティの目的で、あなたのリクエストに関する特定の非個人情報を記録します。',
    privacyContent14: '5.3. FluffleはCloudflareのウェブ分析プラットフォームを使用しており、これは個人情報を収集せず、ブラウジング行動を追跡せず、クッキーも使用しません。',
    privacyContent15: '5.4. 詳細については、Fluffleのプライバシーポリシーを参照してください。',
    privacyContent16: '6. プロ機能',
    privacyContent17: '6.1. 将来プロ機能を導入する場合、当社はこれらのサービスを提供するために必要な追加情報（アカウント情報や支払い詳細など）を収集する場合があります。6.2. プロ機能に関連する追加のデータ収集は、実装前に明確に通知され、ユーザーはこれらの機能にオプトインする選択肢を持つことになります。',
    privacyContent18: '7. 情報の共有と開示',
    privacyContent19: '7.1. 当社は、あなたの個人を特定できる情報を外部の当事者に販売、取引、またはその他の方法で転送しません。7.2. 当社は、法律で要求される場合、または当社もしくは他者の権利、プライバシー、安全、財産を保護するために必要な場合に情報を開示することがあります。',
    privacyContent20: '8. データの保持',
    privacyContent21: '当社は、このプライバシーポリシーに概説された目的を達成するために必要な期間、匿名化された検索クエリを保持します。ただし、法律によってより長期の保持期間が要求または許可される場合を除きます。',
  privacyContent22: '9. あなたの選択',
  privacyContent23: '9.1. あなたは、ブラウザの設定を通じてクッキーを無効にすることができます。ただし、これにより検索履歴やお気に入りの結果などの一部の機能の機能性に影響が出る可能性があります。',
  privacyContent24: '9.2. 当社は個人を特定できる情報を収集していないため、個別の検索データへのアクセスや削除を提供することはできません。',
  privacyContent25: '10. 子供のプライバシー',
  privacyContent26: '当社のサービスは13歳未満の方を対象としていません。当社は、13歳未満の子供から個人を特定できる情報を意図的に収集することはありません。あなたが親または保護者で、お子様が当社に個人情報を提供したことに気づいた場合は、当社にご連絡ください。',
  privacyContent27: '11. このプライバシーポリシーの変更',
  privacyContent28: '当社は、随時プライバシーポリシーを更新する場合があります。新しいプライバシーポリシーをこのページに掲載し、このプライバシーポリシーの冒頭にある「最終更新日」を更新することで、変更をお知らせします。',
  privacyContent29: '12. セキュリティ',
  privacyContent30: '当社は、あなたの情報のセキュリティを確保することに専念しています。当社はオンラインで収集する情報を保護し、セキュリティを維持するために、適切な物理的、電子的、管理的手順を実施しています。',
  delete: '削除',
  availableinpro: 'ページあたりの結果数の変更はPROバージョンでのみ利用可能です',
  madebykeyfox: 'Keyfox/Jianhuが❤️を込めて作成'
}