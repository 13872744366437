const ES_BASE_URL = process.env.REACT_APP_ES_URL;
const ES_API_KEY = process.env.REACT_APP_ES_API_KEY;

export async function search(query, page, resultsPerPage, website = '*') {
  const ES_URL = `${ES_BASE_URL}/search-${website}/_search`;

  const response = await fetch(ES_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `ApiKey ${ES_API_KEY}`
    },
    body: JSON.stringify({
      from: page * resultsPerPage,
      size: resultsPerPage,
      query: {
        query_string: {
          query: query
        }
      }
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}

export async function reverseImageSearch(image, includeNsfw) {
  const formData = new FormData();
  formData.append('file', image);
  formData.append('includeNsfw', includeNsfw);
  formData.append('limit', 10);

  const corsAnywhereUrl = 'https://oyster-app-qp8wj.ondigitalocean.app/';
  const response = await fetch(corsAnywhereUrl + 'https://api.fluffle.xyz/v1/search', {
    method: 'POST',
    headers: {
      'User-Agent': 'YourAppName/1.0',
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data.results;
}