import React, { createContext, useState, useContext, useEffect } from 'react';
import { translations, defaultLanguage } from '../translations';

const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);

  useEffect(() => {
    const detectLanguage = () => {
      const savedLanguage = localStorage.getItem('language');
      if (savedLanguage && translations[savedLanguage]) {
        return savedLanguage;
      }

      const browserLang = navigator.language.split('-')[0];
      return translations[browserLang] ? browserLang : defaultLanguage;
    };

    setCurrentLanguage(detectLanguage());
  }, []);

  const changeLanguage = (lang) => {
    if (translations[lang]) {
      setCurrentLanguage(lang);
      localStorage.setItem('language', lang);
    }
  };

  const t = (key, ...args) => {
    let translation = translations[currentLanguage][key] || translations[defaultLanguage][key] || key;
    args.forEach((arg, i) => {
      translation = translation.replace(`{${i}}`, arg);
    });
    return translation;
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, changeLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}