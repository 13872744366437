import React from 'react';
import { motion } from 'framer-motion';

export function DeleteFolderPopup({ folderToDelete, onConfirm, onCancel, t }) {
  return (
    <motion.div
      className="popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-content"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2>{t('deleteFolder')}</h2>
        <p>{t('deleteFolderConfirm', folderToDelete)}</p>
        <div className="popup-actions">
          <motion.button 
            onClick={onConfirm}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {t('yesDelete')}
          </motion.button>
          <motion.button 
            onClick={onCancel}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {t('cancel')}
          </motion.button>
        </div>
      </motion.div>
    </motion.div>
  );
}